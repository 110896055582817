import ConnectionsList from './list';
import ConnectionCreate from './create';
import ConnectionEdit from './edit';
import ConnectionShow from './show';

const resource = {
    list: ConnectionsList,
    create: ConnectionCreate,
    edit: ConnectionEdit,
    show: ConnectionShow,
};

export default resource;