import { useState, useEffect } from "react";
import { useNotify } from "react-admin";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import apiClient from "../../provider/data/passwords";

const ResetPassword = () => {
  const [currentUserId] = useState(localStorage.getItem("id"));
  const [formValues, setFormValues] = useState({});
  const notify = useNotify();

  const handleInputChange = (e) => {
    setFormValues((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const handlePasswordChange = async () => {
    const { newPassword, newPasswordConfirmation } = formValues;

    if (!newPassword || !newPasswordConfirmation) {
      notify(`Tutti i campi sono obbligatori. Compila i campi rimanenti.`, { type: "error" });
      return;
    }

    if (newPassword !== newPasswordConfirmation) {
      notify(`Le tua nuova password ed il suo campo di conferma non coincidono.`, { type: "error" });
      return;
    }

    try {
      const res = await apiClient.changePassword(currentUserId, formValues.newPassword);
      notify(`Password aggiornata con successo.`, { type: "success" });
    } catch (err) {
      console.log({ err });
      notify(`Errore durante l'aggiornamento della password. ${err.message}.`, { type: "error" });
    }
  };

  const FIELD_WIDTH = "300px";
  const fieldsProps = {
    sx: { width: FIELD_WIDTH },
    variant: "outlined",
    type: "password",
    required: true,
    onChange: handleInputChange,
  };

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <CardContent>
        <Typography gutterBottom variant="h5">
          Cambio Password
        </Typography>
        <Typography gutterBottom>
          Questa sezione ti consente di cambiare la password relativa alla tua utenza Superadmin.
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Box>
            <TextField name="newPassword" label="Nuova Password" {...fieldsProps} />
          </Box>
          <Box>
            <TextField name="newPasswordConfirmation" label="Ripeti Nuova Password" {...fieldsProps} />
          </Box>
          <Button variant="contained" sx={{ width: FIELD_WIDTH, mt: 2 }} onClick={handlePasswordChange}>
            Cambia password
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ResetPassword;
