import * as React from 'react';
import { Create, SimpleForm, TextInput, PasswordInput, AutocompleteInput, FormDataConsumer, SelectInput, required, CheckboxGroupInput } from 'react-admin';
import {Grid, Paper} from '@mui/material';
import RetailersChoice from './retailers';

const postDefaultValue = () => ({
    created_at: new Date(),
    updated_at: new Date()
});

const managementSoftware = [{ id: 'winfarm_superm', name: 'WinfarmSuperM' }, { id: 'wingesfar', name: 'Wingesfar' }, { id: 'infofarm', name: 'Infofarm' }, { id: 'facile', name: 'Facile' }, { id: 'planet', name: 'Planet' }, { id: 'farmasimplex', name: 'Farmasimplex' }, { id: 'farmaconsult', name: 'Farmaconsult' }];
const MANAGEMENT_SOFTWARES_FTP = ['wingesfar', 'infofarm', 'winfarm_superm', 'facile', 'planet', 'farmasimplex', 'farmaconsult']

export const ConnectionCreate = () => (
    <Create>
        <SimpleForm defaultValues={postDefaultValue}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextInput source="description" label="Nome della farmacia" validate={[required()]} fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <RetailersChoice />
                </Grid>
                <Grid item xs={4}>
                    <TextInput disabled source="fdirect_retailer_uuid" label="Retailer Uuid" validate={[required()]} fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <SelectInput label="Gestionale" source="management_software" validate={[required()]} choices={managementSoftware} fullWidth />
                </Grid>
                <Grid item xs={8}>

                </Grid>
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.management_software === 'winfarm' &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Paper sx={{ display: { xl: 'none', xs: 'block' } }}>
                                    <TextInput type="hidden" source="tmp_file_path" defaultValue="C:\\estrattore\\tmp" />
                                    <TextInput type="hidden" source="source_path" defaultValue="/src/" />
                                </Paper>
                                <Grid item xs={8}>
                                    <TextInput label="Path del file del db" source="system_path"
                                               required defaultValue="C:/program files (x86)/winfarm/archivi/arc2000.phs" fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput label="Nome del server" source="server_name"
                                               required defaultValue="localhost" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput label="Utente Database" source="system_user"
                                               required defaultValue="sysdba" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <PasswordInput label="Password Database" source="system_password"
                                                   required defaultValue="masterkey" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput label="Utente API (e-mail)" type="email" source="email"
                                               required fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <PasswordInput label="Password API" source="password"
                                                   required fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxGroupInput label="Task attivi" source="tasks" choices={[
                                        { id: 'casuals', name: 'Causali' },
                                        { id: 'sells', name: 'Vendite' },
                                        { id: 'purchases', name: 'Acquisti' },
                                        { id: 'suppliers', name: 'Fornitori' },
                                        { id: 'stocks', name: 'Stock' },
                                    ]} />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData, ...rest}) => (MANAGEMENT_SOFTWARES_FTP.includes(formData.management_software)) &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput label="Utente FTP" source="system_user"
                                               required fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <PasswordInput label="Password FTP" source="system_password"
                                                   required fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </FormDataConsumer>
            </Grid>
        </SimpleForm>
    </Create>
);

export default ConnectionCreate;