import * as React from "react";
import {
    Edit,
    SimpleForm,
    NumberInput,
    BooleanInput,
    useRecordContext,
    SelectInput,
    fetchUtils, SaveButton, EditActions, EditButton, SimpleFormIterator
} from 'react-admin';
import axios from "axios";
import Toolbar from "@mui/material/Toolbar";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const apiUrlSuperadmin = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/users/superadmin';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
            Accept: 'application/json'
        });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
}


const FeesDetails = () => {
    const [fees, setFees] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const record = useRecordContext();
    React.useEffect(() => {
        const fetchData = async () => {
            if (!"company_type_id" in record) {
                return;
            }

            try {
                const response = await httpClient(
                    `${apiUrlSuperadmin}/company/get-default-fees?company_type_id=${record.company_type_id}`
                );

                setFees(response.json);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [record]);

    if (isLoading) {
        return <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>;
    }

    if (error) {
        return <p>Si è verificato un errore: {error.message}</p>;
    }

    if (!fees) {
        return null;
    }

    let defaultPharmaPercentage = (parseFloat(fees.PHARMA) * 100).toFixed(2);
    let defaultSopOtcPercentage = (parseFloat(fees.SOP_OTC) * 100).toFixed(2);
    let defaultParafPercentage = (parseFloat(fees.PARAF) * 100).toFixed(2);

    return (
        <Box sx={{ width: '100%', marginBottom: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Modifica Commissioni
            </Typography>
            <Typography variant="h6" gutterBottom>
                Valori Di Default:
            </Typography>
            <div><span>PHARMA: <b>{defaultPharmaPercentage}%</b>; </span> <span>SOP OTC: <b>{defaultSopOtcPercentage}%</b>; </span> <span>PARAF: <b>{defaultParafPercentage}%</b>;</span></div>
        </Box>
    );
};

const FeeEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const FeeEditTitle = () => {
    const record = useRecordContext();
    let preName;
    if(record){
        switch (parseInt(record.company_type_id)){
            case 1:
                preName = "Industria";
                break;
            case 2:
                if(parseInt(record.chain_type) === 0){
                    preName = 'Network';
                }else{
                    preName = 'Centrale';
                }
                break;
            default:
                preName = "Retailer";
        }
        return <span>{preName}:  {record ? `${record.name}` : ''}</span>;
    }else{
        return <span></span>;
    }
};

const validatePercentage = (value) => {
    if (value < 0 || value > 100) {
        return "Il valore deve essere compreso tra 0 e 100";
    }
    return undefined;
};

const SellerPaysBuyerFees = () => {
    const record = useRecordContext();

    const sellerPaysBuyerFeesOptions = [
        { id: 'no_pay', name: 'Nessuno' },
        { id: 'all_customers', name: 'Tutti' },
        { id: 'direct_customers', name: 'Tutti i clienti diretti' },
        { id: 'no_direct_customers', name: 'Tutti TRANNE i clienti diretti' },
        { id: 'retailer', name: 'Solo Retailer' },
        { id: 'direct_retailer', name: 'Solo Retailer clienti' },
        { id: 'no_direct_retailer', name: 'Solo Retailer NON clienti' },
        { id: 'chain', name: 'Solo Centrali' },
        { id: 'direct_chain', name: 'Solo Centrali clienti' },
        { id: 'no_direct_chain', name: 'Solo Centrali NON clienti' },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {record && record.company_type_id === 1 && (
                <SelectInput
                    label="L'azienda paga le commissioni di vendita"
                    source="seller_pays_buyer_fees"
                    choices={sellerPaysBuyerFeesOptions}
                    optionText="name"
                    optionValue="id"
                    multiple
                />
            )}
        </div>
    );
};

export const FeesEdit = () => (
    
    <Edit title={<FeeEditTitle />}>
        <SimpleForm toolbar={<FeeEditToolbar />}>
            <FeesDetails/>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <NumberInput
                    label="Pharma (%)"
                    source="fee_pharma"
                    validate={[validatePercentage]}
                    style={{ marginRight: '15px' }} />
                <NumberInput
                    label="SOP OTC (%)"
                    source="fee_sop_otc"
                    validate={[validatePercentage]}
                    style={{ marginRight: '15px' }} />
                <NumberInput
                    label="Paraf (%)"
                    source="fee_paraf"
                    validate={[validatePercentage]}
                />
            </div>
            <SellerPaysBuyerFees />
        </SimpleForm>
    </Edit>
);