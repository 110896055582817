import UserRegistrationList from "./list";
import UserRegistrationEdit from "./edit";
import UserRegistrationShow from "./show";

const resource = {
  list: UserRegistrationList,
  edit: UserRegistrationEdit,
  show: UserRegistrationShow,
};

export default resource;
