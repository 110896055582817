import { fetchUtils } from "react-admin";
import { stringify } from 'query-string';

const apiUrl = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/api';
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
          Accept: 'application/json'
        });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
}

export default {
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const query = {
            perPage: perPage,
            page: page,
            ...params.filter,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, {method: 'GET'}).then(({ headers, json }) => ({
            data: json.data,
            total: json.totalCount,
        }));
    },
    getTotalInvoicesValues: (resource, params) => {
        const {page, perPage} = params.pagination;
        const query = {
            perPage: perPage,
            page: page,
            ...params.filter,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url, {method: 'GET'}).then(({ headers, json }) => ({
            totNet: json.totNet,
            totGross: json.totGross,
        }));
    },
}