const proxyUrl =  (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740')

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request( proxyUrl + '/login' , {
      method: "POST",
      body: JSON.stringify({ email: username, password, app: "superadmin" }),
      credentials:'include',
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const { username, role, id } = response;

        localStorage.setItem("username", username);
        localStorage.setItem('role', role);
        localStorage.setItem('id', id);

        return Promise.resolve();
      })
      .catch(() => {
        throw new Error("Network error");
      });
  },
  logout: async () => {
    localStorage.removeItem("username");

    const request = new Request( proxyUrl + '/logout' , {
      method: "POST",
      credentials:'include',
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    const response = await fetch(request)

    if(response.status !== 200) return Promise.reject();
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: async () => {   
    const request = new Request(proxyUrl +  "/check-auth", {
      method: "POST",
      credentials: 'include',
      headers: new Headers({ "Content-Type": "application/json" }),

    });  
    const response = await fetch(request)

    if(response.status !== 200) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // Questo fa una cosa molto carina in ottica RBAC
  // è futuribile
  getPermissions: () => {
    const role = localStorage.getItem('role');
    return role ? Promise.resolve(role) : Promise.reject();
  },
  getIdentity: () =>
    Promise.resolve({
      id: "user",
      fullName: localStorage.getItem("username"),
      avatar: null,
    }),
};

export default authProvider;
