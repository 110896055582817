import {
  List,
  Datagrid,
  TextField,
  ChipField,
  useRecordContext,
  FilterLiveSearch,
} from "react-admin";

import { useMutation } from "react-query";

import {
  Box,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";

import "./styles.scss";

import LoginAsButton from "../../component/loginButton";
import FeesEditButton from "../../component/feesEditButton";

const companiesFilters = [
  <FilterLiveSearch label="P.Iva" source="vat" />,
  <FilterLiveSearch label="P.E.C." source="pec" />,
  <FilterLiveSearch label="Nome" source="name" />,
];

const PostPanel = () => {
  const record = useRecordContext();
  return (
    <Box
      sx={{
        backgroundColor: "#FAFAFA",
      }}
    >
      {record?.hasUser && (
        <>
          <Typography variant="h6" gutterBottom>
            Utenti collegati:
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.users.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <LoginAsButton
                      userId={user.id}
                      userStatus={user.status}
                    ></LoginAsButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      {record?.hasAffiliates && (
        <>
          <Typography variant="h6" gutterBottom>
            PDV Affiliati:
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id company</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>P.IVA</TableCell>
                <TableCell>Tipologia</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.affiliates.map((affiliate) => (
                <TableRow
                  key={affiliate.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  expand={<>ciao</>}
                >
                  <TableCell>{affiliate.id}</TableCell>
                  <TableCell>{affiliate.name ? affiliate.name : affiliate.affiliate_name} </TableCell>
                  <TableCell>{affiliate.vat}</TableCell>
                  <TableCell>{affiliate.company_type_id == 3 ? 'Farmacia' : affiliate.company_type_id == 4 ? 'Parafarmacia' : ''}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </Box>
  );
};

const NetworksList = () => (
  <List filters={companiesFilters}>
    <Datagrid
      expand={<PostPanel />}
      isRowExpandable={(row) => (row.hasUser || row.hasAffiliates)}
      bulkActionButtons={false}
    >
      <TextField label="Id" source="id" />
      <TextField label="Nome" source="name" />
      <TextField label="P.Iva" source="vat" />
      <TextField label="P.E.C." source="pec" />
      <ChipField label="Tipologia" source="typology" />
      <FeesEditButton resource={"networks"} />
    </Datagrid>
  </List>
);

export default NetworksList;
