import * as React from "react";
import { Admin, Resource, CustomRoutes, combineDataProviders } from 'react-admin';
import { Route } from "react-router-dom";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import italianMessages from './i18n/it';
import { Login, Layout } from './layout';
//page
import companies from './page/companies';
import retailers from './page/retailers';
import chains from './page/chains';
import networks from './page/networks';
import connections from './page/connections';
import payments from './page/order-managment/payments';
import orders from './page/order-managment/orders';
import invoices from './page/order-managment/invoices';
import registration from './page/registration/users-list';
import referencesSold from './page/referencesSold';
import referencesBought from './page/referencesBought';
//provider
import authProvider from './provider/auth'
import userProvider from './provider/data/user';
import companiesProvider from './provider/data/companies';
import retailersProvider from './provider/data/retailers';
import chainsProvider from './provider/data/chains';
import networksProvider from './provider/data/networks';
import connectionsProvider from './provider/data/connections';
import importSessionsProvider from './provider/data/importSessions';
import paymentsProvider from './provider/data/payments';
import ordersProvider from './provider/data/orders';
import invoicesProvider from './provider/data/invoices';

import referencesSoldProvider from './provider/data/referencesSold';
import referencesBoughtProvider from './provider/data/referencesBought';
//custom routes
import ResetPassword from './page/passwords/component';

const i18nProvider = polyglotI18nProvider(locale => {
    // if (locale === 'it') {
    //     return import('./i18n/it').then(messages => messages.default);
    // }

    // Always fallback on italian
    return italianMessages;
}, 'it', [{ locale: 'it', name: 'Italiano' }], { allowMissing: true });

const dataProvider = combineDataProviders((resource => {
    switch (resource){
        case 'users':
            return userProvider;
        case 'payments':
            return paymentsProvider;
        case 'companies':
            return companiesProvider;
        case 'retailers':
            return retailersProvider;
        case 'chains':
            return chainsProvider;
        case 'networks':
            return networksProvider;
        case 'subjects':
            return connectionsProvider;
        case 'import-sessions':
            return importSessionsProvider;
        case 'orders':
            return ordersProvider;
        case 'invoices':
            return invoicesProvider;
        case 'registration':
            return userProvider;
        case 'referencesSold':
            return referencesSoldProvider;
        case 'referencesBought':
            return referencesBoughtProvider;
        default:
            throw new Error("No data provider matching for requested resource: ", resource);
    }
}))

const App = () => (
    <Admin
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        loginPage={Login}
        layout={Layout}
    >
        <Resource name="companies" {...companies}/>
        <Resource name="retailers" {...retailers}/>
        <Resource name="chains" {...chains}/>
        <Resource name="networks" {...networks}/>
        <Resource name="subjects" {...connections}/>
        <Resource name="payments" {...payments}/>
        <Resource name="orders" {...orders}/>
        <Resource name="invoices" {...invoices}/>
        <Resource name="registration" {...registration}/>
        <Resource name="referencesSold" {...referencesSold}/>
        <Resource name="referencesBought" {...referencesBought}/>
        <CustomRoutes>
            <Route path="/reset-password" element={<ResetPassword />} />
        </CustomRoutes>
    </Admin>
)

export default App;
