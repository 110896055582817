import {
    List,
    Datagrid,
    TextField,
    WrapperField,
    useRecordContext,
    FilterLiveSearch
} from 'react-admin';


import {
    Box,
    TableCell,
    TableRow,
    Table,
    TableBody,
    TableHead,
    Typography,
} from '@mui/material';

import './styles.scss'

import LoginAsButton from '../../component/loginButton';
import FeesEditButton from "../../component/feesEditButton";


const companiesFilters = [
    <FilterLiveSearch label="P.Iva" source="vat" />,
    <FilterLiveSearch label="P.E.C." source="pec" />,
    <FilterLiveSearch label="Nome" source="name" />,
    <FilterLiveSearch label="Gestionale in uso" source="management" />,
    <FilterLiveSearch label="Codice univoco" source="unique_code" />,
    <FilterLiveSearch label="Contatto telefonico" source="telephone" />,
    <FilterLiveSearch label="Indirizzo" source="full_address" />,
];

const PostPanel = () => {
    const record = useRecordContext();
    return (
        <Box
            sx={{
                backgroundColor: '#FAFAFA'
            }}
        >
            <Typography
                variant="h6"
                gutterBottom
            >
                Utenti collegati:
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {record?.users?.map((user) => (
                        <TableRow
                            key={user?.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                {user?.id}
                            </TableCell>
                            <TableCell>{user?.email}</TableCell>
                            <TableCell><LoginAsButton userId={user?.id} userStatus={user?.status} ></LoginAsButton></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};

const AddressField = () => {
    const record = useRecordContext();
    return (
        <div>
            <p style={{marginBottom: '0px', marginTop: '0px'}}>
                <span style={{ color: 'blue', fontWeight: 'bold' }}>Indirizzo: </span><span>{record.address}</span>
            </p>
            <p style={{marginBottom: '0px', marginTop: '0px'}}>
                <span style={{ color: 'blue', fontWeight: 'bold' }}>Città: </span><span>{record.city}</span>
            </p>
            <p style={{marginBottom: '0px', marginTop: '0px'}}>
                <span style={{ color: 'blue', fontWeight: 'bold' }}>C.A.P.: </span><span>{record.cap}</span>
            </p>
            <p style={{marginBottom: '0px', marginTop: '0px'}}>
                <span style={{ color: 'blue', fontWeight: 'bold' }}>Provincia: </span><span>{record.province}</span>
            </p>
        </div>
    );
}

const TelephoneField = () => {
    const record = useRecordContext();
    if(record.landline_phone !== null){
        return (
            <div>
                <p style={{marginBottom: '0px', marginTop: '0px'}}>
                    <span style={{ color: 'blue', fontWeight: 'bold' }}>Tel. Mobile: </span><span>{record.telephone_number}</span>
                </p>
                <p style={{marginBottom: '0px', marginTop: '0px'}}>
                    <span style={{ color: 'blue', fontWeight: 'bold' }}>Tel. Fisso: </span><span>{record.landline_phone}</span>
                </p>
            </div>
        );
    }else{
        return (
            <div>
                <p style={{marginBottom: '0px', marginTop: '0px'}}>
                    <span style={{ color: 'blue', fontWeight: 'bold' }}>Tel. Mobile: </span><span>{record.telephone_number}</span>
                </p>
            </div>
        );
    }
}


const CompaniesList = () => (
    <List filters={companiesFilters}>
        <Datagrid
            expand={<PostPanel />}
            isRowExpandable={row => row.hasUser}
            bulkActionButtons={false}
        >
            <TextField label="Id" source="id" />
            <TextField label="Nome" source="name" />
            <TextField label="P.Iva" source="vat" />
            <TextField label="P.E.C." source="pec" />
            <TextField label="Gestionale in uso" source="management" />
            <TextField label="Codice Univoco" source="unique_code" />
            <WrapperField label="Contatti telefonici">
                <TelephoneField/>
            </WrapperField>
            <WrapperField label="Indirizzo">
                <AddressField/>
            </WrapperField>
            <FeesEditButton resource={"companies"}/>
        </Datagrid>
    </List>
);

export default CompaniesList;