import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  TextInput,
  BooleanField,
  FunctionField,
  DateField,
  useRecordContext,
} from "react-admin";

import {
  Box,
  Grid,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  Card,
} from "@mui/material";

const getStatusDescription = (status) => {
  switch (status) {
    case 0:
      return "Draft (ordine in preparazione)";
    case 1:
      return "Inviato da retailer ad industria";
    case 2:
      return "Rifiutato da industria";
    case 3:
      return "Approvato da industria";
    case 4:
      return "Parzialmente approvato da industria";
    case 5:
      return "Spedito";
    case 7:
      return "Rifiutato da distributore";
    case 8:
      return "Approvato da distributore";
    case 9:
      return "Parzialmente approvato da distributore";
    case 10:
      return "In attesa di approvazione dai singoli retailer";
    case 11:
      return "Approvato dai singoli retailer e inviato ad industria";
    case 12:
      return "Pagamento del retailer richiesto";
    case 13:
      return "Pagamento dell'acquirente in fase di elaborazione"
    case 14:
        return "Pagamento dell'acquirente non riuscito, nuovo tentativo in corso"
    case 15:
        return "Pagamento del venditore in fase di elaborazione"
    case 16:
        return "Pagamento del venditore non riuscito, nuovo tentativo in corso"
    case 20:
      return "Spedito";
    default:
      return "";
  }
};

const OrdersFilter = (props) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const monthChoices = [
    { id: '1', name: 'gennaio' },
    { id: '2', name: 'febbraio' },
    { id: '3', name: 'marzo' },
    { id: '4', name: 'aprile' },
    { id: '5', name: 'maggio' },
    { id: '6', name: 'giugno' },
    { id: '7', name: 'luglio' },
    { id: '8', name: 'agosto' },
    { id: '9', name: 'settembre' },
    { id: '10', name: 'ottobre' },
    { id: '11', name: 'novembre' },
    { id: '12', name: 'dicembre' },
  ];
  const yearChoices = Array.from({ length: 10 }, (val, index) => ({ id: (currentYear - index).toString(), name: (currentYear - index).toString() }));

  return (
    <Filter {...props}>
      <TextInput label="Retailer" source="retailerName" resettable alwaysOn />
      <TextInput label="Azienda" source="companyName" resettable alwaysOn />
      <TextInput label="Distributore" source="distributorName" resettable alwaysOn />
      <SelectInput
        style={{ width: "400px" }}
        source="orderStatus"
        label="Stato dell'ordine"
        choices={[
          { id: 0, name: "Draft" },
          { id: 1, name: "Inviato da retailer ad industria" },
          { id: 2, name: "Rifiutato da industria" },
          { id: 3, name: "Approvato da industria" },
          { id: 4, name: "Parzialmente approvato da industria" },
          { id: 7, name: "Rifiutato da distributore" },
          { id: 8, name: "Approvato da distributore" },
          { id: 9, name: "Parzialmente approvato da distributore" },
          { id: 10, name: "In attesa di approvazione dai singoli retailer" },
          { id: 11, name: "Approvato dai singoli retailer e inviato ad industria" },
          { id: 12, name: "Pagamento del retailer richiesto" },
          { id: 13, name: "Pagamento dell'acquirente in fase di elaborazione" },
          { id: 14, name: "Pagamento dell'acquirente non riuscito, nuovo tentativo in corso" },
          { id: 15, name: "Pagamento del venditore in fase di elaborazione" },
          { id: 16, name: "Pagamento del venditore non riuscito, nuovo tentativo in corso" },
          { id: 20, name: "Spedito" },
        ]}
        alwaysOn
      />
      <SelectInput
          label="Seleziona il mese"
          source="month"
          choices={monthChoices}
          //defaultValue={currentMonth.toString()}
          resettable
          alwaysOn
        />
      <SelectInput
          label="Seleziona l'anno"
          source="year"
          choices={yearChoices}
          // defaultValue={currentYear.toString()}
          resettable
          alwaysOn
        />
    </Filter>
  );
};

const OrderDetailModal = ({ record: order, open, handleClose }) => {
  const paymentMethodChosen =
    order.condition && order.condition.financialCondition.length && order.financialID
      ? order.condition.financialCondition.find((cond) => cond.id === order.financialID)
      : null;

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={(e, reason) => {
        handleClose();
      }}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={6}>
            {/* INFO GENERALI */}
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Info Generali
            </Typography>
            <Typography>Numero Ordine: {order?.orderRequest.orderNumber}</Typography>
            <Typography>ID Ordine: {order?.id}</Typography>
            <Typography>Creato in data: {new Date(order.orderRequest.submittedAt).toLocaleString("it-IT")}</Typography>
            <Typography>Processato in Data: {new Date(order.processedAt).toLocaleString("it-IT")}</Typography>
            <Typography>
              Modalità di pagamento: {paymentMethodChosen ? paymentMethodChosen["typeText"] : "-"}
            </Typography>
            <Typography>Status: {getStatusDescription(order?.status)}</Typography>

            {/* INFO CONDIZIONE */}
            <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
              Info Condizione
            </Typography>
            <Typography>ID Condizione: {order?.conditionID}</Typography>
            {/* {record?.rejectionReason && <Typography>Motivo del rifiuto: {record?.rejectionReason}</Typography>} */}
            <Typography>Tipo Condizione: {getConditionTypeLabel(order?.condition)}</Typography>

            {/* INFO RETAILER */}
            <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
              Info Retailer
            </Typography>
            <Typography>ID: {order?.retailer?.id}</Typography>
            <Typography>Nome: {order?.retailer?.name}</Typography>
            <Typography>
              Indirizzo: {order?.retailer?.address}, {order?.retailer?.city} {order?.retailer?.province} (
              {order?.retailer?.cap}){" "}
            </Typography>
            <Typography>Pec: {order?.retailer?.pec}</Typography>
            <Typography>P.IVA: {order?.retailer?.vat}</Typography>

            {/* INFO AZIENDA */}
            <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
              Info Azienda
            </Typography>
            <Typography>ID: {order?.company?.id}</Typography>
            <Typography>Nome: {order?.company?.name}</Typography>
            <Typography>
              Indirizzo: {order?.company?.address} {order?.company?.city} {order?.company?.province} (
              {order?.company?.cap})
            </Typography>
            <Typography>Pec: {order?.company?.pec}</Typography>
            <Typography>P.IVA: {order?.company?.vat}</Typography>

            {/* INFO DISTRIBUTORE */}
            {order.distributor ? (
              <>
                <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
                  Info Distributore:
                </Typography>
                <Typography>ID: {order?.distributor?.id}</Typography>
                <Typography>Nome: {order?.distributor?.name}</Typography>
                <Typography>
                  Indirizzo: {order?.distributor?.address} {order?.distributor?.city} {order?.distributor?.province} (
                  {order?.distributor?.cap})
                </Typography>
                <Typography>Pec: {order?.distributor?.pec}</Typography>
                <Typography>P.IVA: {order?.distributor?.vat}</Typography>
              </>
            ) : null}

            {/* INFO FATTURE */}
            {order.invoiceResult ? (
              <>
                <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
                  Info Fatture:
                </Typography>
                <Typography>Stato: {order?.invoiceResult?.status}</Typography>
                <Typography>N° ordine Teamsystem: {order?.invoiceResult?.invoiceCount}</Typography>
                <Typography>Importo netto: {order?.invoiceResult?.net}</Typography>
                <Typography>Importo lordo: {order?.invoiceResult?.gross}</Typography>
                <Typography>Importo iva: {order?.invoiceResult?.vat}</Typography>
              </>
            ) : null}
          </Grid>
          <Grid item md={6}>
            {/* INFO SPEDIZIONE */}
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
              Info Spedizione
            </Typography>
            <Typography>
              Indirizzo di spedizione: {order?.orderRequest.deliveryAddress?.address},{" "}
              {order?.orderRequest.deliveryAddress?.city} {order?.orderRequest.deliveryAddress?.province} (
              {order?.orderRequest.deliveryAddress?.cap})
            </Typography>
            <Typography>Spedizione 1° ordine: {order?.condition.shipping?.daysFirstOrder} giorni</Typography>
            <Typography>Spedizione Standard: {order?.condition.shipping?.daysStandardOrder} giorni</Typography>
            <Typography>Costo: {order?.condition.shipping?.cost} €</Typography>
            <Typography>Ordine minimo per gratuita : {order?.condition.shipping?.upperFreeLimit} €</Typography>
            <Typography>
              {order?.courierInfo ? (
                <>
                  <Typography>Corriere: {order?.courierInfo?.courierName}</Typography>
                  <Typography>Codice Tracciamento: {order?.courierInfo?.trackerLink}</Typography>
                  <Typography>
                    Data di consegna prevista:{" "}
                    {new Date(order?.courierInfo?.expectedDeliveryDate).toLocaleString("it-IT", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
                  </Typography>
                </>
              ) : (
                "Info corriere: non presenti"
              )}
            </Typography>

            {/* INFO SCONTI */}
            <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
              Info Extra Sconti
            </Typography>
            <Typography>Extra Sconti totali applicati: % {order?.extraDiscountPerc}</Typography>
            {order?.extras?.map((extra, i) => {
              return (
                <React.Fragment key={i}>
                  {extra.singleOrderValue ? (
                    <>
                      <Typography sx={{ fontWeight: "bold" }}>Sconto su valore ordine</Typography>
                      {extra.singleOrderValue.settings.map((setting) => (
                        <Typography sx={{ ml: 2, color: setting.reached ? "green" : "red" }}>
                          {setting.text}: {setting.discount}% {setting.reached ? "applicato" : "non applicato"}
                        </Typography>
                      ))}
                    </>
                  ) : null}

                  {extra.termsOfPayment ? (
                    <>
                      <Typography sx={{ fontWeight: "bold" }}>Sconto su termini di pagamento</Typography>
                      {extra.termsOfPayment.settings.map((setting) => (
                        <Typography sx={{ ml: 2, color: setting.reached ? "green" : "red" }}>
                          {setting.text}: {setting.discount}% {setting.reached ? "applicato" : "non applicato"}
                        </Typography>
                      ))}
                    </>
                  ) : null}

                  {extra.numberOfBrandsHandled ? (
                    <>
                      <Typography sx={{ fontWeight: "bold" }}>Sconto su numero di brand ordinati</Typography>
                      {extra.numberOfBrandsHandled.settings.map((setting) => (
                        <Typography sx={{ ml: 2, color: setting.reached ? "green" : "red" }}>
                          {setting.text}: {setting.discount}% {setting.reached ? "applicato" : "non applicato"}
                        </Typography>
                      ))}
                    </>
                  ) : null}

                  {extra.numberOfReferencesByBrand ? (
                    <>
                      <Typography sx={{ fontWeight: "bold" }}>Sconto su numero referenze per brand</Typography>
                      {extra.numberOfReferencesByBrand.settings.map((setting) => (
                        <Typography sx={{ ml: 2, color: setting.reached ? "green" : "red" }}>
                          {setting.text}: {setting.discount}% {setting.reached ? "applicato" : "non applicato"}
                        </Typography>
                      ))}
                    </>
                  ) : null}

                  {extra.singleWarehouseShipment ? (
                    <>
                      <Typography sx={{ fontWeight: "bold" }}>Sconto su spedizione a singolo magazzino</Typography>
                      {extra.singleWarehouseShipment.settings.map((setting) => (
                        <Typography sx={{ ml: 2, color: setting.reached ? "green" : "red" }}>
                          {setting.text}: {setting.discount}% {setting.reached ? "applicato" : "non applicato"}
                        </Typography>
                      ))}
                    </>
                  ) : null}
                </React.Fragment>
              );
            })}

            {/* INFO COMMISSIONI */}
            <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
              Info Commissioni
            </Typography>
            <Typography>
              Commissioni Industria: {order.sellerFeeInfos ? `${order.sellerFeeInfos.total} € + IVA` : "-"}
            </Typography>
            <Typography>
              Commissioni Retailer: {order.buyerFeeInfos ? `${order.buyerFeeInfos.total} € + IVA` : "-"}
            </Typography>

            {/* INFO APPROVAZIONE */}
            <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
              Info Approvazione
            </Typography>
            <Typography>Quantità Selezionata: {order?.quantitySelected} </Typography>
            <Typography>Quantità Approvata: {order?.quantityApproved || "-"} </Typography>
            <Typography>Quantità Espositore: {order?.quantityExpos} </Typography>
            <Typography>Quantità Espositore Approvata: {order?.quantityExposApproved || "-"} </Typography>
            {order?.chainShippingCost ? (
              <Typography>Costo spedizione Catena: {order?.chainShippingCost} euro</Typography>
            ) : null}
            <Typography>Subtotale: {order.subtotal ? order.subtotal.toFixed(2) : "-"} euro</Typography>
            <Typography>
              Subtotale senza Extrasconti: {order.subtotalWithoutExtra ? order.subtotalWithoutExtra.toFixed(2) : "-"} €
            </Typography>
            <Typography>
              Subtotale Approvato: {order.subtotalApproved ? order.subtotalApproved.toFixed(2) : "-"} €
            </Typography>

            {/* INFO CONDIZIONI FINANZIARIE*/}
            <Typography variant="h6" style={{ fontWeight: "bold", marginTop: "20px" }}>
              Info Metodi Pagamento
            </Typography>
            {order.condition?.financialCondition?.map((financialCond) => (
              <Typography>
                {financialCond.typeText}: a partire da € {financialCond.limit}
              </Typography>
            ))}
          </Grid>
          <Grid item md={12}>
            <ReferencesDetail order={order} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const getConditionTypeLabel = (condition) => {
  if (!condition) return "-";

  switch (condition.type) {
    case 1:
      return "Continuativa";
    case 2:
      return "Prestagionale";
    case 3:
      return "Promozionale";
    case 4:
      return "Lancio";
    case 5:
      return "Win/Win";
    case 6:
      return "Win/Win";
    case 7:
      return "Sentinella";
    default:
      return "-";
  }
};

const ShowOrderDetails = ({ record }) => {
  const [showModal, setShowModal] = React.useState(false);

  const handleButtonClick = () => {
    setShowModal((state) => !state);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleButtonClick}>
        Mostra
      </Button>
      <OrderDetailModal record={record} open={showModal} handleClose={() => setShowModal(false)} />
    </>
  );
};

const ReferencesDetail = ({ order }) => {
  const isSplittedOrder = order.deliverySplitForAffiliates && order.deliverySplitForAffiliates.length > 1;
  const DEFAULT_ORDER_SPLIT = "summary";
  const [selectedOrderSplit, setSelectedOrderSplit] = useState(DEFAULT_ORDER_SPLIT);

  return (
    <Card sx={{ background: "#FFF5D9", p: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        Info Referenze
      </Typography>
      {isSplittedOrder ? (
        <>
          <Select
            size="small"
            value={selectedOrderSplit}
            onChange={(e) => setSelectedOrderSplit(e.target.value)}
            sx={{ mb: 2, width: "300px", background: "white" }}
          >
            <MenuItem value={"summary"}>Riepilogo</MenuItem>
            {order.deliverySplitForAffiliates.map((orderSplit, idx) => (
              <MenuItem value={orderSplit.id}>{orderSplit.retailer.name}</MenuItem>
            ))}
          </Select>

          {selectedOrderSplit === DEFAULT_ORDER_SPLIT ? (
            <GeneralSummaryTable order={order} />
          ) : (
            <ReferencesTable
              order={order.deliverySplitForAffiliates.find((orderSplit) => orderSplit.id === selectedOrderSplit)}
            />
          )}
        </>
      ) : (
        <ReferencesTable order={order} />
      )}
    </Card>
  );
};

const ReferencesTable = ({ order }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Brand</TableCell>
            <TableCell>Minsan/PARAF</TableCell>
            <TableCell>Referenza</TableCell>
            <TableCell>UMI</TableCell>
            <TableCell>IVA</TableCell>
            <TableCell>Sconto %</TableCell>
            <TableCell>Q.tá tot. Ordine</TableCell>
            <TableCell>Q.tá in Espó</TableCell>
            <TableCell>Prezzo cessione</TableCell>
            <TableCell>Prezzo netto</TableCell>
            <TableCell>Subtotale netto</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.references?.length ? (
            <>
              {order.references.map((ref) => (
                <TableRow key={ref.code}>
                  <TableCell>{ref.brand}</TableCell>
                  <TableCell>{ref.code}</TableCell>
                  <TableCell>{ref.name}</TableCell>
                  <TableCell>{ref.umi}</TableCell>
                  <TableCell>{ref.vat}%</TableCell>
                  <TableCell>{ref.discount}%</TableCell>
                  <TableCell>{ref.quantity}</TableCell>
                  <TableCell>{ref.exposQuantity}</TableCell>
                  <TableCell>€ {ref.salePrice}</TableCell>
                  <TableCell>€ {ref.salePriceDiscounted}</TableCell>
                  <TableCell>€ {ref.subtotalDiscounted}</TableCell>
                </TableRow>
              ))}
              <TableRow
                key={"totals"}
                sx={{ td: { fontWeight: "bold" }, "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Totali</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  € {order.subtotalApproved ? order.subtotalApproved.toFixed(2) : "-"} / €{" "}
                  {order.subtotal ? order.subtotal.toFixed(2) : "-"}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell>Nessuna referenza presente.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const GeneralSummaryTable = ({ order }) => {
  const totals = order.deliverySplitForAffiliates.reduce(
    (accumulator, split) => {
      return {
        quantitySelected: accumulator.quantitySelected + split.quantitySelected,
        quantityApproved: accumulator.quantityApproved + (split.quantityApproved || 0),
        quantityExpos: accumulator.quantityExpos + (split.quantityExpos || 0),
        quantityExposApproved: accumulator.quantityExposApproved + (split.quantityExposApproved || 0),
        subtotalWithoutExtra: accumulator.subtotalWithoutExtra + (split.subtotalWithoutExtra || 0),
        subtotalApproved: accumulator.subtotalApproved + (split.subtotalApproved || 0),
      };
    },
    {
      quantitySelected: 0,
      quantityApproved: 0,
      quantityExpos: 0,
      quantityExposApproved: 0,
      subtotalWithoutExtra: 0,
      subtotalApproved: 0,
    }
  );
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Ragione sociale</TableCell>
            <TableCell>Quantita' ordinata</TableCell>
            <TableCell>Quantita' approvata</TableCell>
            <TableCell>Quantita' expo</TableCell>
            <TableCell>Quantita' expo approvata</TableCell>
            <TableCell>Subtotale senza extrasconti</TableCell>
            <TableCell>Totale approvato</TableCell>
            <TableCell>Costo spedizione</TableCell>
            <TableCell>Stato</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {order.deliverySplitForAffiliates.map((orderSplit) => (
            <TableRow key={orderSplit.id}>
              <TableCell>{orderSplit.retailer ? orderSplit.retailer.name : "-"}</TableCell>
              <TableCell>{orderSplit.quantitySelected}</TableCell>
              <TableCell>{orderSplit.quantityApproved || "-"}</TableCell>
              <TableCell>{orderSplit.quantityExpos}</TableCell>
              <TableCell>{orderSplit.quantityExposApproved || "-"}</TableCell>
              <TableCell>
                € {orderSplit.subtotalWithoutExtra ? orderSplit.subtotalWithoutExtra.toFixed(2) : "-"}
              </TableCell>
              <TableCell>€ {orderSplit.subtotalApproved ? orderSplit.subtotalApproved.toFixed(2) : "-"}</TableCell>
              <TableCell>€ {orderSplit.shippingCost}</TableCell>
              <TableCell>{getStatusDescription(orderSplit.status) || "-"}</TableCell>
            </TableRow>
          ))}
          <TableRow
            key={"totals"}
            sx={{ td: { fontWeight: "bold" }, "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>Totali</TableCell>
            <TableCell>{totals.quantitySelected}</TableCell>
            <TableCell>{totals.quantityApproved || "-"}</TableCell>
            <TableCell>{totals.quantityExpos}</TableCell>
            <TableCell>{totals.quantityExposApproved || "-"}</TableCell>
            <TableCell>€ {totals.subtotalWithoutExtra.toFixed(2)}</TableCell>
            <TableCell>€ {totals.subtotalApproved.toFixed(2)}</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ExpandRow = () => {
  const record = useRecordContext();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FAFAFA",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Ulteriori informazioni
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Numero dell'ordine Fdirect</TableCell>
              <TableCell>Id Utente</TableCell>
              <TableCell>Numero dell'ordine Teamsystem</TableCell>
              <TableCell>Importo netto</TableCell>
              <TableCell>Importo lordo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record?.orderRequest?.invoiceResults?.map((order, i) => (
              <TableRow key={i}>
                <TableCell>
                  {order?.orderNumber || 'non presente'}
                </TableCell>
                <TableCell>
                  {order?.companyId || 'non presente'}
                </TableCell>
                <TableCell>
                  {order?.invoiceCount || 'non presente'}
                </TableCell>
                <TableCell>
                  {order?.net != null ? `${order.net}€` : 'non presente'}
                </TableCell>
                <TableCell>
                  {order?.gross != null ? `${order.gross}€` : 'non presente'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

const OrderList = (props) => {
  return (
    <List filters={<OrdersFilter />} {...props}>
      <Datagrid expand={ExpandRow} isRowExpandable={(record) => record?.orderRequest?.invoiceResults?.length > 0} bulkActionButtons={false}>
      <TextField label="N. Ordine" source="orderRequest.orderNumber" />
        <DateField label="Data" source="orderRequest.submittedAt" />
        <FunctionField label="Condizione" render={(record) => getConditionTypeLabel(record.condition)} />
        <TextField label="Retailer" source="retailer.name" />
        <TextField label="Azienda" source="company.name" />
        <FunctionField
          label="Distributore"
          render={(record) => (record.distributor && record.distributor.name ? record.distributor.name : "-")}
        />
        ;
        <BooleanField label="Transfer Order" source="isTransferOrder" looseValue={true} />
        <FunctionField
          label="Tipologia T.O"
          render={(record) =>
            record.isTransferOrder
              ? record.transferOrderType === 1
                ? `Consegna distributore`
                : `Consegna industria`
              : "-"
          }
        />
        ;
        <TextField label="N. referenze" source="referencesCount" />
        <FunctionField
          label="Tot. approvato"
          render={(record) => `${record.subtotalApproved ? `${record.subtotalApproved.toFixed(2)}€` : "-"}`}
        />
        <FunctionField
          label="Commissioni"
          render={(record) => (
            <>
              <span>{record.buyerFeeInfos ? `${record.buyerFeeInfos.total.toFixed(2)} € (B)` : "-"}</span>
              <br></br>
              <span>{record.sellerFeeInfos ? `${record.sellerFeeInfos.total.toFixed(2)} € (S)` : "-"}</span>
            </>
          )}
        />
        <FunctionField label="Status" render={(record) => getStatusDescription(record.status)} />
        <FunctionField render={(record) => <ShowOrderDetails record={record} />} />
      </Datagrid>
    </List>
  );
};

export default OrderList;
