import * as React from "react";
import { AppBar, Logout, UserMenu, useTranslate } from "react-admin";
import { Link } from "react-router-dom";
import { Box, MenuItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyIcon from '@mui/icons-material/Key';

import Logo from "./Logo";

const ConfigurationMenu = React.forwardRef((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItem
      component={Link}
      // @ts-ignore
      ref={ref}
      {...props}
      to="/configuration"
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>{translate("pos.configuration")}</ListItemText>
    </MenuItem>
  );
});
const ChangePasswordPage = (props) => {
  return (
    <MenuItem
      component={Link}
      {...props}
      to="/reset-password"
    >
      <ListItemIcon>
        <KeyIcon />
      </ListItemIcon>
      <ListItemText>Cambio Password</ListItemText>
    </MenuItem>
  );
};

const CustomUserMenu = () => (
  <UserMenu>
    <ConfigurationMenu />
    <ChangePasswordPage />
    <Logout />
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  return (
    <AppBar {...props} color="default" elevation={1} userMenu={<CustomUserMenu />}>
      <Logo />
      <Typography
        variant="h6"
        color="inherit"
        marginLeft="110px"
        sx={{
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
        id="react-admin-title"
      />
    </AppBar>
  );
};

export default CustomAppBar;
