import { fetchUtils } from "react-admin";
import { stringify } from 'query-string';
import axios from 'axios';

const dataImporterSubjectsBaseUrl = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/data-import/superadmin/api/subjects';
const sessionBySubjectPath = 'import-sessions';
const httpClient = fetchUtils.fetchJson;

export default {
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            perPage: perPage,
            page: page,
            filter: JSON.stringify(params.filter),
        };
        const url = `${dataImporterSubjectsBaseUrl}/${params.filter.subjectId}/${sessionBySubjectPath}?${stringify(query)}`;
        return axios.get(url, {withCredentials:true})
            .then(function (response) {
                return {
                    data: response.data,
                    total: response.data.length,
                };
            });
    },
}
