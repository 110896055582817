import { fetchUtils } from "react-admin";
import simpleRestProvider from 'ra-data-simple-rest';

const dataImporterBaseUrl = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/data-import/superadmin/api';

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(dataImporterBaseUrl, fetchJson)
export default dataProvider