import * as React from 'react';
import { Edit, SimpleForm, TextInput, PasswordInput, FormDataConsumer, SelectInput, required } from 'react-admin';
import { Grid } from '@mui/material';
import BasicActionsWithModal from './modal';

const managementSoftware = [{ id: 'winfarm_superm', name: 'WinfarmSuperM' }, { id: 'wingesfar', name: 'Wingesfar' }, { id: 'infofarm', name: 'Infofarm' }, { id: 'facile', name: 'Facile' }, { id: 'planet', name: 'Planet' }, { id: 'farmasimplex', name: 'Farmasimplex' }, { id: 'farmaconsult', name: 'Farmaconsult' }];
const MANAGEMENT_SOFTWARES_FTP = ['wingesfar', 'infofarm', 'winfarm_superm', 'facile', 'planet', 'farmasimplex', 'farmaconsult']
export const ConnectionEdit = () => (
    <Edit actions={<BasicActionsWithModal page="edit" />}>
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput source="description" label="Nome della farmacia" validate={[required()]} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextInput disabled source="fdirect_retailer_uuid" label="Retailer Uuid" validate={[required()]} fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <SelectInput label="Gestionale" source="management_software" choices={managementSoftware} fullWidth />
                </Grid>
                <Grid item xs={8}>

                </Grid>
                <FormDataConsumer>
                    {({formData, ...rest}) => formData.management_software === 'winfarm' &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <TextInput label="Path del file del db" source="system_path" required fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextInput label="Nome del server" source="server_name" required fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput label="Utente Database" source="system_user" required fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <PasswordInput disabled label="Password Database" source="system_password" fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput label="Utente API (e-mail)" placeholder="mario@rossi.it" type="email" source="email" required fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <PasswordInput disabled label="Password API" source="password" required fullWidth />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput label="Import giornaliero dalle" placeholder="09:00" source="daily_active_from" fullWidth />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput label="Import giornaliero fino" placeholder="19:00" source="daily_active_to" fullWidth />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput label="Import storico dalle" placeholder="09:00" source="historical_active_from" fullWidth />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextInput label="Import storico fino" placeholder="19:00" source="historical_active_to" fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({formData, ...rest}) => (MANAGEMENT_SOFTWARES_FTP.includes(formData.management_software)) &&
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput label="Utente FTP" source="system_user" required fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <PasswordInput disabled label="Password FTP" source="system_password" fullWidth />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </FormDataConsumer>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default ConnectionEdit;