import { NumberField, DateField, Show, SimpleShowLayout, TextField, FunctionField, useRecordContext, TabbedShowLayout, ListContextProvider, Datagrid, useGetList, useList } from 'react-admin';
import { Grid, Card, CardContent, Typography, Stack, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ImportChart from "./graph";
import {formatDistance} from "date-fns";
import { it } from 'date-fns/locale'
import BasicActionsWithModal from './modal';
import * as React from "react";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const SoftwareDependentField = (props) => {
    const record = useRecordContext(props);
    if (!record) return null;
    return record.management_software === props.software && <Grid item xs={props.columns}>
        <Card>
            <CardContent sx={{pt: 2}}>
                <Typography color="text.secondary" gutterBottom>
                    {props.title}
                </Typography>
                <TextField source={props.source}/>
            </CardContent>
        </Card>
    </Grid>;
};

const WinfarmTimeSlot = (props) => {
    const record = useRecordContext(props);
    if (!record) return null;
    return <Grid item xs={props.columns}>
        <Card>
            <CardContent sx={{pt: 2}}>
                <Typography color="text.secondary" gutterBottom>
                    {props.title}
                </Typography>
                <FunctionField
                    render={record => {
                        return record[props.source] ? record[props.source] : 'SEMPRE';
                    }} />
            </CardContent>
        </Card>
    </Grid>;
};

const sort = { field: 'id', order: 'DESC' };

const ImportSessionsList = (props) => {
    const record = useRecordContext(props);
    if (!record) return null;
    const { data, isLoading } = useGetList('import-sessions', {
        filter: {subjectId: record.id},
        pagination: { page: 1, perPage: 10 },
        sort,
    });
    const listContext = useList({ data, isLoading });

    return (
        <ListContextProvider value={listContext}>
            <Datagrid bulkActionButtons={false} isRowSelectable={record => false}>
                <TextField source="id" />
                <TextField source="entity" />
                <NumberField source="progress" />
                <TextField source="status" />
                <NumberField source="rows_total" />
                <NumberField source="rows_imported" />
                <NumberField source="rows_in_error" />
                <DateField showTime={true} source="updated_at" />
            </Datagrid>
        </ListContextProvider>
    );
};

const ShowPage = () => {
    return (
        <Show actions={<BasicActionsWithModal page="show" />}>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Dati">
                    <SimpleShowLayout>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 430,
                                    }}
                                >
                                    <ImportChart/>
                                </Paper>
                            </Grid>

                            <Grid item xs={12} md={4} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 430,
                                    }}
                                >
                                    <Stack spacing={2}>
                                        <Item>
                                            <Typography color="text.primary" gutterBottom>
                                                Id
                                            </Typography>
                                            <TextField source="description"/>
                                        </Item>
                                        <Item>
                                            <Typography color="text.primary" gutterBottom>
                                                Id
                                            </Typography>
                                            <TextField source="id"/>
                                        </Item>
                                        <Item>
                                            <Typography color="text.primary" gutterBottom>
                                                Fdirect UUID
                                            </Typography>
                                            <TextField source="fdirect_retailer_uuid"/>
                                        </Item>
                                        <Item>
                                            <Typography color="text.primary" gutterBottom>
                                                Gestionale
                                            </Typography>
                                            <TextField source="management_software"/>
                                        </Item>
                                        <Item>
                                            <Typography color="text.primary" gutterBottom>
                                                Ultimo import
                                            </Typography>
                                            <FunctionField
                                                render={record => {
                                                    return formatDistance(new Date(record.last_activity_at), new Date(), { locale: it, addSuffix: true })
                                                }} />
                                        </Item>
                                    </Stack>
                                </Paper>
                            </Grid>

                            <SoftwareDependentField software="wingesfar" columns="3" source="system_user" title="Utente FTP" />
                            <SoftwareDependentField software="infofarm" columns="3" source="system_user" title="Utente FTP" />
                            <SoftwareDependentField software="winfarm_superm" columns="3" source="system_user" title="Utente FTP" />
                            <SoftwareDependentField software="facile" columns="3" source="system_user" title="Utente FTP" />
                            <SoftwareDependentField software="planet" columns="3" source="system_user" title="Utente FTP" />
                            <SoftwareDependentField software="farmasimplex" columns="3" source="system_user" title="Utente FTP" />
                            <SoftwareDependentField software="farmaconsult" columns="3" source="system_user" title="Utente FTP" />
                            <SoftwareDependentField software="winfarm" columns="3" source="system_user" title="Utente Database" />
                            <SoftwareDependentField software="winfarm" columns="3" source="system_path" title="Path del file del database" />
                            <SoftwareDependentField software="winfarm" columns="3" source="server_name" title="Nome del server" />
                            <SoftwareDependentField software="winfarm" columns="3" source="email" title="E-mail API" />
                            <WinfarmTimeSlot columns="3" source="daily_active_from" title="Import Giornalieri attivi dalle ore" />
                            <WinfarmTimeSlot columns="3" source="daily_active_to" title="Import Giornalieri attivi fino alle ore" />
                            <WinfarmTimeSlot columns="3" source="historical_active_from" title="Import Storico attivi dalle ore" />
                            <WinfarmTimeSlot columns="3" source="historical_active_to" title="Import Storico attivi fino alle ore" />

                            <Grid item xs={4}>
                                <Card>
                                    <CardContent sx={{pt: 2}}>
                                        <Typography color="text.secondary" gutterBottom>
                                            Data creazione
                                        </Typography>
                                        <DateField source="created_at"/>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4}>
                                <Card>
                                    <CardContent sx={{pt: 2}}>
                                        <Typography color="text.secondary" gutterBottom>
                                            Data ultimo update
                                        </Typography>
                                        <DateField source="updated_at"/>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </SimpleShowLayout>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Import">
                    <ImportSessionsList />
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export default ShowPage;