import {
  List,
  Datagrid,
  TextField,
  ChipField,
  SelectInput,
  required,
  useRecordContext,
  FilterLiveSearch,
} from "react-admin";

import {
  Box,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
  Typography,
  Button,
} from "@mui/material";

import "./styles.scss";

import LoginAsButton from "../../component/loginButton";
import FeesEditButton from "../../component/feesEditButton";

const companiesFilters = [
  <SelectInput
    validate={required()} //remove empty value
    label="Tipologia"
    source="company_type_id"
    choices={[
      { id: 3, name: "Farmacia" },
      { id: 4, name: "Parafarmacia" },
    ]}
  />,
  <FilterLiveSearch label="P.Iva" source="vat" />,
  <FilterLiveSearch label="P.E.C." source="pec" />,
  <FilterLiveSearch label="Nome" source="name" />,
];

const PostPanel = () => {
  const record = useRecordContext();
  return (
    <Box
      sx={{
        backgroundColor: "#FAFAFA",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Utenti collegati:
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Email</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record?.users?.map((user) => (
            <TableRow
              key={user.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <LoginAsButton
                  userId={user.id}
                  userStatus={user.status}
                ></LoginAsButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {record?.chainAffiliation && (
        <>
          <Typography variant="h6" gutterBottom>
            Catena:
          </Typography>
          <Table>

            <TableBody style={{ backgroundColor: "#B7BFC9" }}>
                <TableRow
                  key={record?.chain?.company?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>Id catena: {record?.chain?.company?.id}</TableCell>
                  <TableCell>
                    {record?.chain?.company?.name ? record?.chain?.company?.name : record?.chain?.company?.chain_name}
                  </TableCell>
                  <TableCell>P.iva: {record?.chain?.company?.vat}</TableCell>
                  <TableCell>Tipo: Catena</TableCell>
                </TableRow>
            </TableBody>

            <TableBody style={{ backgroundColor: "#e4e5e0" }}>
                {
                    record?.chain?.hasUser && record?.chain?.users?.map(user => (
                    <TableRow key={user.id}>
                      <TableCell>Id utente: {user.id} </TableCell>
                      <TableCell>Email: {user.email} </TableCell>
                      <TableCell>Stato: {user.status == 10 ? 'Attivo' : 'Non Attivo'} </TableCell>
                      <TableCell>
                        <LoginAsButton userId={user.id} userStatus={user.status}></LoginAsButton>
                      </TableCell>
                    </TableRow>
                    ))
                }
            </TableBody>

          </Table>
        </>
      )}
    </Box>
  );
};

const RetailersList = () => (
  <List filters={companiesFilters}>
    <Datagrid
      expand={<PostPanel />}
      isRowExpandable={(row) => row.hasUser || row.chainAffiliation}
      bulkActionButtons={false}
    >
      <TextField label="Id" source="id" />
      <TextField label="Nome" source="name" />
      <TextField label="P.Iva" source="vat" />
      <TextField label="P.E.C." source="pec" />
      <ChipField label="Tipologia" source="typology" />
      <ChipField label="Gestionale" source="retailer_management" />
      <FeesEditButton resource={"retailers"} />
    </Datagrid>
  </List>
);

export default RetailersList;
