import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, ListButton, TopToolbar, ShowButton, EditButton, useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import LogViewer from './log';

const dataImporterDownloadUrl = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/data-import/superadmin/api/subjects/winfarm2fdirect/download';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #03a84e',
    boxShadow: 24,
    p: 4,
};

export default function BasicActionsWithModal(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const record = useRecordContext(props);
    const MANAGEMENT_SOFTWARES_FTP = ['wingesfar', 'infofarm', 'winfarm_superm', 'facile', 'planet', 'farmasimplex', 'farmaconsult']
    if (!record) return null;
    const SHOW_FTP_INFO = MANAGEMENT_SOFTWARES_FTP.includes(record.management_software)

    return (
        <TopToolbar>
            {props.page === 'edit' &&<ShowButton />}
            {props.page === 'show' &&<EditButton />}
            <Button label="Mostra Configurazione" startIcon={<PreviewIcon />} sx={{"--Button-gap": "8px"}} size="small" component={Link} color="primary" onClick={handleOpen} />
            <ListButton label="Torna alla lista" />
            <LogViewer />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Configurazione per <strong style={{"text-transform": "uppercase"}}>{record.management_software}</strong>
                    </Typography>
                    <Typography id="modal-modal-field-id" sx={{ mt: 2 }}>
                        Id: <Typography component="span" fontWeight="bolder">{record.id}</Typography>
                    </Typography>
                    {record.management_software === 'winfarm' && <Typography id="modal-modal-winfarm-field-email">
                        Username API: <Typography component="span" fontWeight="bolder">{record.email}</Typography>
                    </Typography>}
                    {SHOW_FTP_INFO && (
                        <>
                            <Typography id="modal-modal-wingesfar-field-ip">
                                Server FTP IP: <Typography component="span" fontWeight="bolder">130.211.49.3</Typography>
                            </Typography>
                            <Typography id="modal-modal-wingesfar-field-hostname">
                                Server FTP Hostname: <Typography component="span" fontWeight="bolder">sftp.fdirect.it</Typography>
                            </Typography>
                            <Typography id="modal-modal-wingesfar-field-ftp-username">
                                Username FTP: <Typography component="span" fontWeight="bolder">{record.system_user}</Typography>
                            </Typography> 
                        </>
                    )}
                    {record.management_software === 'winfarm' && <Typography id="modal-modal-winfarm-field-email">
                        Estrattore: <a href={dataImporterDownloadUrl}>Download</a>
                    </Typography>}
                </Box>
            </Modal>
        </TopToolbar>
    );
}