import React, { useState, useEffect } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  SelectInput,
  useRecordContext,
  useListContext
} from 'react-admin';
import { Box, Grid, TableCell, TableRow, Table, TableBody, TableHead, Typography } from "@mui/material";
import { useDataProvider } from 'react-admin';
import invoicesDataProvider from '../../../provider/data/invoices';

const InvoicesFilter = (props) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const monthChoices = [
        { id: '1', name: 'gennaio' },
        { id: '2', name: 'febbraio' },
        { id: '3', name: 'marzo' },
        { id: '4', name: 'aprile' },
        { id: '5', name: 'maggio' },
        { id: '6', name: 'giugno' },
        { id: '7', name: 'luglio' },
        { id: '8', name: 'agosto' },
        { id: '9', name: 'settembre' },
        { id: '10', name: 'ottobre' },
        { id: '11', name: 'novembre' },
        { id: '12', name: 'dicembre' },
      ];
    const yearChoices = Array.from({ length: 10 }, (val, index) => ({ id: (currentYear - index).toString(), name: (currentYear - index).toString() }));
    return (
      <Filter {...props}>
        <TextInput
          label="Cerca per numero Fattura"
          source="invoiceCount"
          resettable
          alwaysOn
        />
        <TextInput
          label="Cerca per id ordine"
          source="orderId"
          resettable
          alwaysOn
        />
        <SelectInput
          label="Seleziona il mese"
          source="month"
          choices={monthChoices}
        //   defaultValue={currentMonth.toString()}
          resettable
          alwaysOn
        />
        <SelectInput
          label="Seleziona l'anno"
          source="year"
          choices={yearChoices}
          defaultValue={currentYear.toString()}
          resettable
        />
      </Filter>
    );
  };

  const ExpandRow = () => {
    const record = useRecordContext();
    return (
      <>
        <Box
          sx={{
            backgroundColor: "#FAFAFA",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Ulteriori informazioni
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Data dell'ordine</TableCell>
                <TableCell>Numero dell'ordine Fdirect</TableCell>
                <TableCell>Importo netto</TableCell>
                <TableCell>Importo lordo</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
              {
                record.orders.map((order) => (
                  <TableRow key={order.orderId}>
                    <TableCell>
                      {order.dateOrder}
                    </TableCell>
                    <TableCell>
                      {order.orderNumber}
                    </TableCell>
                    <TableCell>
                      {order.net} €
                    </TableCell>
                    <TableCell>
                      {order.gross} €
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Box>
      </>
    );
  };
  
  const InfoBox = ({ totals }) => {
    const { totNet, totGross } = totals;

    return (
        <Box sx={{ margin: "10px"}}>
            <Grid container spacing={2} >
                <Grid item xs={4} style={{margin: '15px', padding: '10px', backgroundColor: '#f2f2f2' }}>
                    <Typography >Totale netto: {totNet} €</Typography>
                </Grid>
                <Grid item xs={4}  style={{margin: '15px', padding: '10px', backgroundColor: '#f2f2f2' }}>
                    <Typography >Totale lordo: {totGross} €</Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const InvoiceListView = () => {
  const [totals, setTotals] = useState({ totNet: 0, totGross: 0 });
  const { page, perPage, filterValues } = useListContext();

  useEffect(() => {
    invoicesDataProvider
      .getTotalInvoicesValues('total-invoice-value', { pagination: { page, perPage }, filter: filterValues })
      .then(response => {
        const { totNet, totGross } = response;
        setTotals({ totNet: totNet, totGross: totGross })
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [filterValues]);

  return (
    <>
    <InfoBox totals={totals}/>
    <Datagrid expand={ExpandRow} isRowExpandable={() => true} bulkActionButtons={false}>
      <TextField label="N° Ordine Teamsystem" source="id" />
      <TextField label="N° Ordini in Fattura" source="ordersCount" />
      <TextField label="Azienda" source="company.name" />
      <TextField label="P.IVA" source="company.vat" />
      <TextField label="Netto" source="net" />
      <TextField label="Lordo" source="gross" />
    </Datagrid>
    </>
  );
};
  
  
const InvoicesList = () => {
  return (
    <List filters={<InvoicesFilter />}>
      <InvoiceListView />
    </List>
  );
};

export default InvoicesList;