import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || "http://localhost:8740") + "/api";
const apiUrlSuperadmin = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || "http://localhost:8740") + "/users/superadmin";
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }
  options.credentials = "include";
  return fetchUtils.fetchJson(url, options);
};

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify([field, order]),
      perPage: perPage,
      page: page,
      filter: JSON.stringify(params.filter),
    };

    const url = `${apiUrl}/superadmin/bestbuyers?${stringify(query)}`;
    return httpClient(url, { method: "GET" })
      .then(({ json }) => {
        return json;
      })
      .catch((e) => console.error(e));
  },
};
