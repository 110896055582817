import React from "react";
import { Edit, SimpleForm, TabbedForm, TextInput, useRecordContext, Show, SelectInput } from "react-admin";
import { Grid, Card, CardContent, Typography, Container, Box } from "@mui/material";
import { LogoUpload } from "./logoUpload";

// ! TODO:const sharedConstants = process.env.SHARED_CONFIG_MOUNT_PATH;
const managementSoftware = [
  { id: 1, name: "Winfarm (Pharmaservice)" },
  { id: 0, name: "Wingesfar (CGM)" },
  { id: 3, name: "Sistema F Platinum (CSF Sistemi)" },
  { id: 7, name: "Farmagest (Farmaconsult)" },
  { id: 11, name: "Infofarm (CGM - S'moove Software)" },
  { id: 4, name: "EasyPharma (Pharmagest Italia - Svemu Informatica)" },
  { id: 17, name: "Id (Pharmagest Italia)" },
  { id: 22, name: "Farmasimplex (CIPCarbone)" },
  { id: 6, name: "Pl@net (Siteam)" },
  { id: 20, name: "Winfarma 2000 (Winfarma)" },
  { id: 9, name: "UFI Farm (UFI)" },
  { id: 5, name: "Farmaevolution (Farmaline)" },
  { id: 2, name: "L-infa (Pharmaservice - Saedi)" },
  { id: 30, name: "Facile (Innovasoft)" },
  { id: 23, name: "Apodesk (CGM - S'moove Software)" },
  { id: 21, name: "Genius (ItalFarma)" },
  { id: 16, name: "Stella (CGM)" },
  { id: 15, name: "Infoshop (Pharmaservice)" },
  { id: 24, name: "L-infa Shop (Saedi)" },
  { id: 25, name: "Pharmastore (CGM)" },
  { id: 10, name: "Farma3 (Farma3Tec Srl)" },
  { id: 26, name: "Cloudfarma (CSF Sistemi)" },
  { id: 14, name: "Facile Business (Innovasoft)" },
  { id: 27, name: "Farmasim III (Siteam)" },
  { id: 28, name: "OTTO (Siteam)" },
  { id: 19, name: "FarmaConn (Software Solutions)" },
  { id: 29, name: "DotFarma (TecnoFarma)" },
  { id: 8, name: "Oliamm (Engineering Ingegneria Informatica)" },
  { id: 12, name: "Altro" },
];

const CompanyData = ({ isFormEditable }) => {
  const record = useRecordContext();
  const isAffiliateNamePopulated = !!record.affiliate_name;

  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              INFORMAZIONI GENERALI
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextInput source="id" label="id" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="name" label="Ragione Sociale" fullWidth disabled={!isFormEditable || isAffiliateNamePopulated} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="company_type_id" label="Tipo Company" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="chain_type" label="Tipo Catena" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="unique_code" label="Codice Univoco" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="vat" label="P.IVA" fullWidth disabled={!isFormEditable || isAffiliateNamePopulated} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="fiscal_code" label="C.F." fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="ministerial_id" label="ID Ministeriale" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <SelectInput
              label="Gestionale Retailer"
              source="retailer_management"
              choices={managementSoftware}
              fullWidth
              disabled={!isFormEditable}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="retailer_management_custom_value" label="Gestionale Retailer altro" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="pharmacy_classification" label="Classificazione Farmacia" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="affiliate_name" label="Nome Affiliato" fullWidth disabled={!isFormEditable} />
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              INDIRIZZO & CONTATTI
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextInput source="pec" label="PEC" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="telephone_number" label="Numero di cellulare" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="fax" label="Fax" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="landline_phone" label="Numero fisso" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="address" label="Indirizzo" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="city" label="Città" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="city_id" label="ID Città" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="cap" label="CAP" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="region" label="Regione" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="region_id" label="ID Regione" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="province" label="Provincia" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="province_id" label="ID Provincia" disabled fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="italy_region_type" label="Tipo Regione Italiana" disabled fullWidth />
          </Grid>
        </Grid>
      </CardContent>

      <CardContent sx={{ minWidth: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              DATI BANCARI
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextInput source="bank_account_details" label="Dettagli Conto Bancario" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="iban" label="IBAN" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="paypal_customer_email" label="Email Cliente PayPal" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="paypal_account_holder" label="Titolare dell'Account PayPal" fullWidth disabled={!isFormEditable} />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="paypal_email" label="Email PayPal" fullWidth disabled={!isFormEditable} />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

const CompanyUsers = ({ isFormEditable }) => {
  const record = useRecordContext();

  if (!record || !record.users) return null;

  return (
    <>
      {record.users.map((user, i) => (
        <Card key={i} style={{ marginBottom: "20px" }} sx={{ minWidth: "100%" }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="p" gutterBottom>
                  {`Utente ${i + 1}: ${user.full_name || "Nome non disponibile"}`}
                </Typography>
              </Grid>
              <Grid item xs={12 / 5}>
                <TextInput source={`users[${i}].id`} label="ID" disabled fullWidth />
              </Grid>
              <Grid item xs={12 / 5}>
                <TextInput source={`users[${i}].username`} label="Username" disabled fullWidth />
              </Grid>
              <Grid item xs={12 / 5}>
                <TextInput source={`users[${i}].full_name`} label="Nome Completo" fullWidth disabled={!isFormEditable} />
              </Grid>
              <Grid item xs={12 / 5}>
                <TextInput source={`users[${i}].email`} label="Email" fullWidth disabled={!isFormEditable} />
              </Grid>
              <Grid item xs={12 / 5}>
                <TextInput source={`users[${i}].status`} label="Status" disabled fullWidth />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

const WinfarmYousignDocument = ({ isFormEditable }) => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <>
      <Card sx={{ minWidth: "100%" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12 / 5}>
              <TextInput source="documentId" label="Yousign Document ID" fullWidth disabled={!isFormEditable} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const FormTabsForUser = (props) => {
  const PHARMARCEUTICAL_COMPANIES_TYPE_ID = 1;

  const record = useRecordContext();
  const isUserACompany = record?.company_type_id === PHARMARCEUTICAL_COMPANIES_TYPE_ID;

  if (isUserACompany) {
    // only for pharma companies
    return (
      <TabbedForm>
        <TabbedForm.Tab label="INFO">
          <CompanyData {...props} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="UTENTI">
          <CompanyUsers {...props} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="LOGO">
          <LogoUpload />
        </TabbedForm.Tab>
      </TabbedForm>
    );
  } else {
    // for centrals, retailers and distributors
    const isWinfarmUser = record.retailer_management === 1; // winfarm
    return (
      <TabbedForm>
        <TabbedForm.Tab label="INFO">
          <CompanyData {...props} />
        </TabbedForm.Tab>
        <TabbedForm.Tab label="UTENTI">
          <CompanyUsers {...props} />
        </TabbedForm.Tab>

        {isWinfarmUser ? (
          <TabbedForm.Tab label="WINFARM - YOUSIGN">
            <WinfarmYousignDocument {...props} />
          </TabbedForm.Tab>
        ) : null}
      </TabbedForm>
    );
  }
};

const SharedRegistrationForm = (props) => {
  console.log({ props });
  const PageAction = props.isFormEditable ? Edit : Show;
  const title = props.isFormEditable ? "EDIT Company&Users" : "SHOW Company&Users";

  return (
    <PageAction {...props} title={title}>
      <FormTabsForUser {...props} />
    </PageAction>
  );
};

export default SharedRegistrationForm;
