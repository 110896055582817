import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }
  options.credentials = "include";
  return fetchUtils.fetchJson(url, options);
};
const apiUrlSuperadmin = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || "http://localhost:8740") + "/users/superadmin";
const apiUrl = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || "http://localhost:8740") + "/users";

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      perPage: perPage,
      page: page,
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrlSuperadmin}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json.data.map((user) => ({
        ...user,
        id: user.company_id,
        user_id: user.id,
        name: user?.name?.replaceAll("&amp;", "&"),
      })),
      total: parseInt(json.totalCount),
    }));
  },
  getOne: async (resource, params) => {
    const companyEndpointURL = `${apiUrlSuperadmin}/tab-list?id=${params.id}`;
    const digitalSignatureEndpointURL = new URL(
      `${window.FTNET_SUPERADMIN_PROXY_BASE_URL}/api/signature-request/document?companyId=${params.id}`
    );

    let [companyResponse, digitalSignatureResponse] = await Promise.all([
      httpClient(companyEndpointURL),
      httpClient(digitalSignatureEndpointURL),
    ]);

    companyResponse = { ...companyResponse, name: companyResponse.name?.replaceAll("&amp;", "&") };
    return { data: { ...companyResponse.json, ...digitalSignatureResponse.json } };
  },
  update: async (resource, params) => {
    console.log("PUT", { resource, params });
    const companyEndpointURL = new URL(`${apiUrlSuperadmin}/tab-list`);
    const digitalSignatureEndpointURL = new URL(`${window.FTNET_SUPERADMIN_PROXY_BASE_URL}/api/signature-request/document`);

    const { documentId, ...company } = params.data;

    const [companyResponse, digitalSignatureResponse] = await Promise.all([
      httpClient(companyEndpointURL, {
        method: "PUT",
        body: JSON.stringify(company),
      }),
      httpClient(digitalSignatureEndpointURL, {
        method: "POST",
        body: JSON.stringify({ documentId, companyId: company.id }),
      }),
    ]);

    return { data: { ...companyResponse.json, ...digitalSignatureResponse.json } };
  },
  updateStatus: (resource, params) => {
    const url = `${apiUrlSuperadmin}/${resource}`;
    const { id, status } = params.data;
    const body = JSON.stringify({ id: id, status: status });
    return httpClient(url, {
      method: "PUT",
      body: body,
    })
      .then(({ json }) => ({ data: json }))
      .catch((e) => console.error(e));
  },
  emailVerification: (params) => {
    const { type } = params.data;
    const url = `${apiUrl}/signup/resend-email-user-verification`;
    if (type === "email") {
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ email: params.data.email }),
      })
        .then(() => true)
        .catch(() => false);
    } else if (type === "pec") {
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify({ pec: params.data.pec }),
      })
        .then(() => true)
        .catch(() => false);
    }
  },
  uploadLogo(logoFile, companyId) {
    const url = `${apiUrlSuperadmin}/company-logo/${companyId}`;
    const formData = new FormData();
    formData.append("logoImg", logoFile);

    return fetchUtils.fetchJson(url, {
      method: "POST",
      body: formData,
      credentials: "include",
    });
  },
  deleteLogo(companyId) {
    const url = `${apiUrlSuperadmin}/company-logo/${companyId}`;

    return httpClient(url, {
      method: "DELETE",
    });
  },
};
