import { fetchUtils } from "react-admin";
import { stringify } from 'query-string';
import axios from 'axios';

const apiUrlSuperadmin = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/users/superadmin';
const dashboardUrl = window.FTNET_DASHBOARD_URL || 'http://localhost:8080';

export default {
    loginAs: (userId) => {
        return axios.get(`${apiUrlSuperadmin}/login-as?userId=${userId}`, {withCredentials:true})
            .then(function (response) {
                // handle success
                console.log(response);
                const needToChoose  = response.data.companies.length > 1;
                console.log(`${dashboardUrl}/login-as/${response.data.tokenLogin}?needToChoose=${needToChoose}`);
                window.open(`${dashboardUrl}/login-as/${response.data.tokenLogin}?needToChoose=${needToChoose}`, '_blank');

            })
            .catch(function (error) {
                // handle error
                console.error(error);
            })
    }
}