import React, { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ChipField,
  BooleanField,
  Filter,
  TextInput,
  SelectInput,
  useRecordContext,
  useDataProvider,
  useNotify,
  useRefresh,
  ShowButton,
  EditButton,
  DateField,
  WrapperField,
  useTheme
} from "react-admin";

import { Box, TableCell, TableRow, Table, TableBody, TableHead, Typography, Button } from "@mui/material";
import userProvider from "../../../provider/data/user";
import LoginAsButton from "../../../component/loginButton"

const UserFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Tipologia"
      source="company_type"
      resettable
      alwaysOn
      choices={[
        { id: "3", name: "Farmacia" },
        { id: "4", name: "Parafarmacia" },
        { id: "1", name: "Industria" },
        { id: "2", name: "Centrale" },
        { id: "5", name: "Distributore"},
        { id: "6", name: "Farmacia Comunale"},
        { id: "7", name: "Centrale Comunale"}
      ]}
    />
    <TextInput label="Cerca per nome" source="name" resettable alwaysOn />
    <TextInput label="Cerca per email" source="email" resettable alwaysOn />
    <TextInput label="Cerca per PEC" source="pec" resettable />
    <TextInput label="Cerca per ID" source="id" resettable />
    <SelectInput
      label="Stato"
      source="status"
      alwaysOn
      resettable
      choices={[
        { id: "10", name: "Registrazione completata" },
        { id: "9", name: "In attesa della convalida P.E.C" },
        { id: "8", name: "Ha inserito i dati della company ma non ha richiesto la convalida della P.E.C" },
        { id: "7", name: "Registrato password ed email verificata" },
        { id: "6", name: "Registrato password ed email da verificare" },
        { id: "-1", name: "Soggetto Cerved inesistente" },
        { id: "-2", name: "Soggetto Cerved con PEC mancante" },
        { id: "-3", name: "PEC non corrispondente in Cerved" },
        { id: "-4", name: "Partita iva non presente in open data" },
        { id: "-8", name: "Bloccato" },
        { id: "-9", name: "Bannato temporaneamente" },
        { id: "-10", name: "Bannato permanentemente" },
      ]}
    />
    <SelectInput
      label="Filtra per data creazione"
      source="created_at"
      resettable
      choices={[
        { id: "now", name: "Creati oggi" },
        { id: "-1 day", name: "Creati ieri" },
        { id: "-1 week", name: "Creati nell'ultima settimana" },
      ]}
    />
    <SelectInput
      label="Filtra per data modifica"
      source="updated_at"
      resettable
      choices={[
        { id: "now", name: "Modificati oggi" },
        { id: "-1 day", name: "Modificati ieri" },
        { id: "-1 week", name: "Modificati nell'ultima settimana" },
      ]}
    />
  </Filter>
);

const ButtonElement = () => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleClick = async () => {
    try {
      const newStatus = record.status !== "Registrazione completata" ? 10 : -8;
      const params = { data: { id: record.user_id , status: newStatus } };
      await dataProvider.updateStatus("registration", params);
      notify(`La modifica di ${record.email} è stata eseguita.`);
      refresh();
    } catch (error) {
      notify(`Si è verificato un errore.`);
    }
  };

  if (!record) return null;
  const isBlocked = record.status !== "Registrazione completata";
  const buttonTxt = isBlocked ? "Attiva" : "Blocca";
  const buttonColor = isBlocked ? "primary" : "secondary";
  const buttonClass = isBlocked ? "MuiButton-containedPrimary" : "MuiButton-outlinedSecondary";
  return (
    <Button onClick={() => handleClick()} color={buttonColor} variant="contained" className={buttonClass}>
      {buttonTxt}
    </Button>
  );
};

const SignupResendEmailVerification = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [type, setType] = useState();
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if  ([6,7,8].includes(parseInt(record.intStatus))) {
      setDisplay(true);
      setType({ type: "email", email: record.email });
    }
    if (parseInt(record.intStatus) === 9) {
      setDisplay(true);
      setType({ type: "pec", pec: record.pec });
    }
  }, [record.status]);

  const handleClick = async () => {
    try {
      const params = { data: type };
      const response = await userProvider.emailVerification(params);
      if (response) notify(`L'invio della conferma a ${type.email || type.pec} è stata eseguita.`);
      else notify(`Si è verificato un errore.`);
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  if (!record) return null;
  return (
    <div>
      <Button onClick={handleClick} color="primary" variant="contained" disabled={!display}>
        {display && type.type} {!display && "No"}
      </Button>
    </div>
  );
};

const expandRow = () => {
  const record = useRecordContext();
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FAFAFA",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Utente: {record.email}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attiva / Blocca utente</TableCell>
              <TableCell>Invia Conferma</TableCell>
              <TableCell>Login</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <ButtonElement />
              </TableCell>
              <TableCell>
                <SignupResendEmailVerification />
              </TableCell>
              <TableCell>
                <LoginAsButton userId={record.user_id} userStatus={record.intStatus}/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

const UserRegistrationList = () => {
  const [theme, setTheme] = useTheme();

  return (
    <List filters={<UserFilter />} resource="registration">
      <Datagrid expand={expandRow} isRowExpandable={() => true}>
        <TextField label="ID" source="company_id" />
        <TextField label="Ragione sociale" source="name" />
        <ChipField label="Tipologia" source="company_type" />
        <ChipField label="Gestionale" source="retailer_management" />
        <WrapperField label="Contatti email">
          <EmailFields color={theme.palette.primary.main}/>
        </WrapperField>
        <WrapperField label="Contatti telefonici">
          <TelephoneFields color={theme.palette.primary.main} />
        </WrapperField>
        <TextField label="P. IVA" source="vat" />
        <TextField label="Stato" source="status" />
        <BooleanField
          label="Consenso Sync"
          looseValue={true}
          source="consent_data_sync"
          valueLabelTrue="Autorizzato"
          valueLabelFalse="Non autorizzato"
        />
        <DateField label="Creato il" source="created_at" />
        <DateField label="Modificato il" source="updated_at" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

const TelephoneFields= ({color}) => {
  return (
    <>
      <div style={{ color, fontWeight: 'bold' }}>Tel. Mobile: </div><TextField label="cellulare" source="telephone_number" />
      <div style={{ color, fontWeight: 'bold' }}>Tel. Fisso: </div><TextField label="telefono fisso" source="landline_phone" />
    </>
  )
}

const EmailFields= ({color}) => {
  return (
    <>
      <div style={{ color, fontWeight: 'bold' }}>Email: </div><EmailField source="email" />
      <div style={{ color, fontWeight: 'bold' }}>Pec: </div><EmailField source="pec" />
    </>
  )
}


export default UserRegistrationList;
