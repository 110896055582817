import { ImageInput, ImageField, useRecordContext, useNotify } from "react-admin";
import { Button, Typography, Grid, CardContent } from "@mui/material";
import { useState } from "react";
import userProvider from "../../../provider/data/user";

export function LogoUpload() {
  const notify = useNotify();
  const record = useRecordContext();

  const [logo, setLogo] = useState(record.logo ? record.logo[0] : undefined);
  const [hasUserInteractedWithDropZone, setHasUserInteractedWithDropZone] = useState(false);

  const onImageChange = (file) => {
    if (!file) {
      // we are deleting the image
      deleteLogo();
    } else {
      setHasUserInteractedWithDropZone(true);
    }

    setLogo(file);
  };

  const deleteLogo = async () => {
    try {
      const companyId = record.id;
      const response = await userProvider.deleteLogo(companyId);
      notify(`Logo rimosso correttamente`, { type: "success" });
    } catch (error) {
      notify(`La rimozione del logo non e' andata a buon fine.`, { type: "error" });
      console.log({ error });
    }
  };

  const uploadLogo = async () => {
    try {
      const companyId = record.id;
      const response = await userProvider.uploadLogo(logo, companyId);
      setHasUserInteractedWithDropZone(false);
      notify(`Logo caricato correttamente`, { type: "success" });
    } catch (error) {
      notify(`L'upload del logo non e' andato a buon fine.`, { type: "error" });
      console.log({ error });
    }
  };

  return (
    <>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              UPLOAD DEL LOGO AZIENDALE DI {record.name.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ImageInput
              accept={"image/*"}
              source="logo"
              label="Carica un logo con sfondo trasparente e con dimensioni non superiori a X (da definire)"
              onChange={onImageChange}
              sx={{
                ".RaFileInput-dropZone": {
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={uploadLogo}
              color="primary"
              variant="contained"
              disabled={!hasUserInteractedWithDropZone || !logo}
            >
              UPLOAD
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}
