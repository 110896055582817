import {
    List,
    Datagrid,
    TextField,
    ChipField,
    FilterButton,
    CreateButton,
    useRecordContext,
    useListContext,
    FilterList,
    FilterListItem,
    TopToolbar,
    ExportButton,
    ShowButton,
    EditButton,
    TextInput,
    FunctionField,
    DateField
} from 'react-admin';

import {
    Box,
    TableCell,
    TableRow,
    Table,
    TableBody,
    TableHead,
    Typography
} from '@mui/material';

import './styles.scss'

const connectionsFilters = [
    <TextInput source="q" label="Ricerca" alwaysOn />,
    <FilterList label="Gestionale" source="management_software">
        <FilterListItem label="Winfarm" value={{ management_software: 'winfarm' }} />
        <FilterListItem label="WinfarmSuperM" value={{ management_software: 'winfarm_superm' }} />
        <FilterListItem label="Wingesfar" value={{ management_software: 'wingesfar' }} />
        <FilterListItem label="Infofarm" value={{ management_software: 'infofarm' }} />
        <FilterListItem label="Facile" value={{ management_software: 'facile' }} />
        <FilterListItem label="Planet" value={{ management_software: 'planet' }} />
        <FilterListItem label="Farmasimplex" value={{ management_software: 'farmasimplex' }} />
        <FilterListItem label="Farmaconsult" value={{ management_software: 'farmaconsult' }} />
    </FilterList>
];

const SubjectPanel = () => {
    const subject = useRecordContext();
    return (
        <Box
            sx={{
                backgroundColor: '#FAFAFA'
            }}
        >
            <Typography
                variant="h6"
                gutterBottom
            >
                Dettagli:
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Retailer Uuid</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        key={subject.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell>
                            {subject.id}
                        </TableCell>
                        <TableCell>{subject.fdirect_retailer_uuid}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};

const ListActions = () => {
    const { total, isLoading } = useListContext();
    return (
        <TopToolbar>
            <FilterButton />
            <CreateButton/>
            <ExportButton disabled={isLoading || total === 0} />
        </TopToolbar>
    );
}

const ConnectionsList = () => (
    <List actions={<ListActions/>} filters={connectionsFilters}>
        <Datagrid
            rowClick="show"
            expand={<SubjectPanel />}
            isRowExpandable={row => (row.management_software === 'winfarm')}
        >
            <TextField label="Id" source="id" />
            <TextField label="Descrizione" source="description" />
            <ChipField label="Gestionale" source="management_software" />
            <DateField label="Ultima attività" source='last_activity_at'/>
            <ShowButton label="Mostra" />
            <EditButton label="Modifica" />
        </Datagrid>
    </List>
);

export default ConnectionsList;