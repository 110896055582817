import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  DateField,
  SelectInput,
  useRecordContext,
  FunctionField,
  required,
} from "react-admin";

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";

const PaymentsFilter = (props) => {
  return (
    <Filter {...props}>
      <SelectInput
        label="Seleziona il metodo di pagamento"
        source="payment"
        validate={required()}
        alwaysOn={true}
        initialValue="stripe"
        choices={[
          { id: "paypal", name: "Paypal" },
          { id: "stripe", name: "Stripe" },
        ]}
      />
      <SelectInput
        label="Filtro per tipo"
        source="type"
        alwaysOn
        initialValue=""
        choices={[
          { id: "error", name: "Errore" },
          { id: "success", name: "Successo" },
          { id: "", name: "Tutti gli ordini" },
        ]}
      />
    </Filter>
  );
};

const ButtonElement = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  if (!record) return null;
  const handleButtonClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (record.paymentType === "stripe") {
    return (
      <>
        <Button variant="outlined" onClick={handleButtonClick}>
          Vedi Dettagli
        </Button>
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
          <DialogActions>
            <Button onClick={handleClose}>X</Button>
          </DialogActions>
          <DialogContent>
            {record?.success ? (
              <Box>
                <Typography>ID ordine fdirect: {record?.companyOrderID}</Typography>
                <Typography>ID ordine stripe: {record?.success?.id}</Typography>
                <Typography>Status: {record?.success?.status}</Typography>
                <Typography>Info:</Typography>
                <Box sx={{ ml: 4 }}>
                  <Typography>ID: {record?.success?.id}</Typography>
                  <Typography>Utente stripe: {record?.success?.customer}</Typography>
                  <Typography>
                    Totale ordine:{" "}
                    {record.success && record.success.amount_received
                      ? (record.success.amount_received / 100).toFixed(2)
                      : "-"}{" "}
                    {record?.success?.currency}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography>ID ordine fdirect: {record?.companyOrderID}</Typography>
                <Typography>
                  Codice errore: {record?.error?.statusCode} {record?.error?.code}
                </Typography>
                <Typography>Tipo di errore: {record?.error?.type}</Typography>
                <Typography>Messaggio: {record?.error?.message}</Typography>
                <Typography>Parametro errato: {record?.error?.param}</Typography>
                <Typography>
                  {" "}
                  Link utili:
                  <Box sx={{ ml: 4 }}>
                    {record?.error?.request_log_url ? (
                      <Typography>Visualizzare la transazione Stripe: {record?.error?.request_log_url}</Typography>
                    ) : (
                      ""
                    )}
                    <Typography>Documentazione errore Stripe: {record?.error?.doc_url}</Typography>
                  </Box>
                </Typography>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }

  if (record.paymentType === "paypal") {
    return (
      <>
        <Button variant="outlined" onClick={handleButtonClick}>
          Vedi Dettagli
        </Button>
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
          <DialogActions>
            <Button onClick={handleClose}>X</Button>
          </DialogActions>
          <DialogContent>
            {record?.success ? (
              <Box>
                <Typography>ID ordine fdirect: {record?.companyOrderID}</Typography>
                <Typography>ID ordine paypal: {record?.success?.id}</Typography>
                <Typography>Status: {record?.success?.status}</Typography>
                <Typography>Payer:</Typography>
                <Box sx={{ ml: 4 }}>
                  <Typography>ID: {record?.success?.payer?.payer_id}</Typography>
                  <Typography>Email: {record?.success?.payer?.email_address}</Typography>
                  <Typography>Nome: {record?.success?.payer?.name?.given_name}</Typography>
                  <Typography>Cognome:{record?.success?.payer?.name?.surname} </Typography>
                </Box>
                <Typography>Payment:</Typography>
                <Box sx={{ ml: 4 }}>
                  <Typography>
                    Euro tot: {record?.success?.purchase_units[0]?.payments?.captures[0]?.amount?.value}
                  </Typography>
                  <Typography>
                    Commissioni paypal:{" "}
                    {
                      record?.success?.purchase_units[0]?.payments?.captures[0]?.seller_receivable_breakdown?.paypal_fee
                        ?.value
                    }
                  </Typography>
                  <Typography>
                    Euro netto:{" "}
                    {
                      record?.success?.purchase_units[0]?.payments?.captures[0]?.seller_receivable_breakdown?.net_amount
                        ?.value
                    }
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography>ID ordine fdirect: {record?.companyOrderID}</Typography>
                <Typography>ID errore: {record?.error?.name}</Typography>
                <Typography>Status: {record?.error?.code}</Typography>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </>
    );
  }
};

const PaymentsList = () => {
  const getAmount = (record) => {
    let amount = "€ ";
    switch (record.paymentType) {
      case "stripe":
        amount += record.success && record.success.amount ? (record.success.amount / 100).toFixed(2) : "-";
        break;
      case "paypal":
        try {
          amount +=
            record.success.purchase_units[0].payments.captures[0].seller_receivable_breakdown.gross_amount.value;
        } catch {
          amount += "-";
        }
        break;
    }
    return amount;
  };

  const getCommissions = (record) => {
    let amount = "€ ";
    switch (record.paymentType) {
      case "stripe":
        amount += "-"; // the api is not providing visibility over the paid commissions to stripe
        break;
      case "paypal":
        try {
          amount += record.success.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee.value;
        } catch {
          amount += "-";
        }
        break;
    }
    return amount;
  };

  const getNetAmount = (record) => {
    let amount = "€ ";
    switch (record.paymentType) {
      case "stripe":
        amount += "-";
        break;
      case "paypal":
        try {
          amount += record.success.purchase_units[0].payments.captures[0].seller_receivable_breakdown.net_amount.value;
        } catch {
          amount += "-";
        }
        break;
    }
    return amount;
  };

  return (
    <div>
      <List filters={<PaymentsFilter />}>
        <Datagrid>
          <TextField label="Company ID" source="companyID" />
          <TextField label="Company Name" source="companyName" />
          <TextField label="Company Pec" source="companyPec" />
          <TextField label="Company Type" source="companyType" />
          <TextField label="Tipo pagamento" source="paymentType" />
          <FunctionField label="Importo" render={(record) => getAmount(record)} />
          <FunctionField label="Commissioni" render={(record) => getCommissions(record)} />
          <FunctionField label="Importo netto" render={(record) => getNetAmount(record)} />
          <DateField label="Data ordine" source="date" showTime />
          <ButtonElement label="Dettagli ordine" />
        </Datagrid>
      </List>
    </div>
  );
};

export default PaymentsList;
