import * as React from 'react';
import {useEffect, useCallback} from 'react';
import {Button, useRecordContext} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import {Link} from "react-router-dom";
import { LazyLog } from 'react-lazylog';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let dataImporterSubjectsLogUrl = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/data-import/superadmin/api/log/{{subjectId}}';
export default function LogViewer(props) {
    const [open, setOpen] = React.useState(false);
    const [currentText, updateText] = React.useState("");
    const subject = useRecordContext(props);
    if (!subject) return null;

    const getLog = useCallback(() => {
        const updatedUrl = `${dataImporterSubjectsLogUrl.replace('{{subjectId}}', subject.id)}`;
        axios.get(updatedUrl, {withCredentials:true}).then(res => {
            updateText(res.data);
        });
    }, [subject]);

    useEffect(() => {
        getLog();
    }, [currentText, getLog]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button label="Mostra Log" startIcon={<ViewHeadlineIcon />} sx={{"--Button-gap": "8px"}} size="small" component={Link} color="primary" onClick={handleClickOpen} />
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Log
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <div style={{ height: "100%", width: "100%" }}>
                    <LazyLog extraLines={1} enableSearch text={currentText} caseInsensitive />
                </div>
            </Dialog>
        </div>
    );
}