import { fetchUtils } from "react-admin";
import { stringify } from 'query-string';
import axios from 'axios';

const apiUrlSuperadmin = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/users/superadmin';
const dashboardUrl = window.FTNET_DASHBOARD_URL || 'http://localhost:8080';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({
          Accept: 'application/json'
        });
    }
    options.credentials = 'include';
    return fetchUtils.fetchJson(url, options);
}

export default {
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            perPage: perPage,
            page: page,
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrlSuperadmin}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data.map(el => ({ ...el, name: el?.name?.replaceAll("&amp;", "&") })),
            total: json.totalCount,
        }));
    },
    getOne: (resource, params) => {
        const url = `${apiUrlSuperadmin}/company-get-one?id=${params.id}`;

        return httpClient(url)
            .then(({ json }) => ({
                data: { ...json, name: json?.name?.replaceAll("&amp;", "&") },
            }));
    },
    update: (resource, params) => {
        const url = `${apiUrlSuperadmin}/company-update?id=${params.data.id}`;
        const payload = {};
        if(params.data.fee_paraf !== null){
            payload.fee_paraf = parseFloat(params.data.fee_paraf)/100;
        }
        if(params.data.fee_pharma !== null){
            payload.fee_pharma = parseFloat(params.data.fee_pharma)/100;
        }
        if(params.data.fee_sop_otc !== null){
            payload.fee_sop_otc = parseFloat(params.data.fee_sop_otc)/100;
        }
        if(params.data.seller_pays_buyer_fees !== null){
            payload.seller_pays_buyer_fees = params.data.seller_pays_buyer_fees;
        }
        console.log('params.data.seller_pays_buyer_fees', params.data.seller_pays_buyer_fees);
        return httpClient(url, {
            method: "PUT",
            body: JSON.stringify(payload),
        }).then(({ json }) => ({ data: json }));
    },
    getDefaultFees: (params) => {
        const url = `${apiUrlSuperadmin}/company/get-default-fees?company_type_id=${params.company_type_id}`;

        return httpClient(url)
            .then(({ json }) => ({
                data: json,
            }));
    }
}