import React from 'react';
import { useEffect, useState } from "react";
import {useRecordContext} from "react-admin";
import {Card, CardHeader, CardContent, Paper} from '@mui/material';
import { subDays } from 'date-fns';
import axios from 'axios';

import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';
import {styled} from "@mui/material/styles";

const dataImporterSubjectsStatisticsUrl = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/data-import/superadmin/api/subjects/{{subjectId}}/statistics';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const dateFormatter = (date: number): string =>
    new Date(date).toLocaleDateString();

const lastDay = subDays(new Date(), 1);
lastDay.setHours(0, 0, 0)
const tenDaysAgo = subDays(new Date(), 9);
tenDaysAgo.setHours(0, 0, 0)

interface TotalByDay {
    date: number;
    sells: number;
    purchases: number;
    stocks: number;
}

window.getImportPerDay = (subject: subject): TotalByDay[] => {
    const lastMonthDays = Array.from({ length: 10 }, (_, i) => subDays(lastDay, i));

    return lastMonthDays.map(date => ({
        date: date.getTime(),
        sells: Math.floor(Math.random() * (10000 - 0) + 0),
        purchases: Math.floor(Math.random() * (6000 - 0) + 0),
        stocks: Math.floor(Math.random() * (9000 - 0) + 0),
    }));
};

export default function ImportChart() {
    const subject = useRecordContext();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const data = [];
    const lastMonthDays = Array.from({ length: 10 }, (_, i) => subDays(lastDay, i));
    // Note: the empty deps array [] means
    // this useEffect will run once
    // similar to componentDidMount()
    useEffect(() => {
        axios.get(dataImporterSubjectsStatisticsUrl.replace('{{subjectId}}', subject.id), {withCredentials:true})
            .then(
                (result) => {
                    for (let instance in result.data) {
                        let mydata = (result.data[instance])
                        mydata.date = new Date(mydata.date).getTime()
                        data.push(mydata)
                    }
                    setItems(data.reverse())
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])
    return (
        <Card>
            <CardHeader title="Import ultimi 10 giorni" />
            <CardContent>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <AreaChart data={items}>
                            <defs>
                                <linearGradient id="sells" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#0CA589" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#0CA589" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="purchases" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#233b63" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#233b63" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="stocks" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#7a7e87" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#7a7e87" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="date" name="Date" type="number" scale="time"
                                   domain={[
                                       tenDaysAgo.getTime(),
                                       lastDay.getTime(),
                                   ]}
                                   tickFormatter={dateFormatter}
                            />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip
                                cursor={{ strokeDasharray: '1 1' }}
                                formatter={(value: any, name, props) => {
                                    let label = ''
                                    switch(name) {
                                        case 'sells':
                                            label = 'Vendite'
                                            break;
                                        case 'purchases':
                                            label = 'Acquisti'
                                            break;
                                        case 'stocks':
                                            label = 'Stock'
                                            break;
                                        default:
                                            label = name
                                    }
                                    return [value, label]
                                }}
                                labelFormatter={(label: any) =>
                                    dateFormatter(label)
                                }
                            />
                            <Area type="monotone" dataKey="sells" label="Vendite" stroke="#0CA589" strokeWidth={3} fill="url(#sells)" />
                            <Area type="monotone" dataKey="purchases" label="Acquisti" stroke="#233b63" strokeWidth={3} fill="url(#purchases)" />
                            <Area type="monotone" dataKey="stocks" label="Stock" stroke="#7a7e87" strokeWidth={3} fill="url(#stocks)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    )
}