import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Groups3Icon from '@mui/icons-material/Groups3';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import EuroIcon from '@mui/icons-material/Euro';
import HubIcon from '@mui/icons-material/Hub';
import GroupIcon from '@mui/icons-material/Group';
import ListIcon from '@mui/icons-material/List';
import WorkIcon from '@mui/icons-material/Work';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LogoCompany from "./LogoCompany";
import LogoRetailer from "./LogoRetailer";
import LogoChains from "./LogoChains";
import LogoNetworks from "./LogoNetworks";

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import companies from '../page/companies';
import SubMenu from './SubMenu';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuCompanies';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
        menuCompanies: true,
        menuDataImport: true,
        menuOrders: true,
        menuRegistrationList: true,
        queues: true,
        references: true,
    });
    const [link, setLink] = useState("/cron-board/queues");
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            <SubMenu
                handleToggle={() => handleToggle('menuCompanies')}
                isOpen={state.menuCompanies}
                name="pos.menu.companies"
                icon={<Groups3Icon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/companies"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.companies.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LogoCompany />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/retailers"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.retailers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LogoRetailer />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/chains"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.chains.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LogoChains />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/networks"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.networks.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LogoNetworks />}
                    dense={dense}
                />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuDataImport')}
                isOpen={state.menuDataImport}
                name="pos.menu.dataImport"
                icon={<ImportExportIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/subjects"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.connections.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<HubIcon />}
                    dense={dense}
                />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuOrders')}
                isOpen={state.menuOrders}
                name="pos.menu.orders"
                icon={<AddShoppingCartIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/payments"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.orders.payments`, {
                        smart_count: 2,
                    })}
                    leftIcon={<EuroIcon />}
                />
                <MenuItemLink
                    to="/orders"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.orders.orders`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ListAltIcon />}
                />
                <MenuItemLink
                    to="/invoices"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.orders.invoices`, {
                        smart_count: 2,
                    })}
                    leftIcon={<ReceiptIcon />}
                />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('menuRegistrationList')}
                isOpen={state.menuRegistrationList}
                name="pos.menu.registrationList"
                icon={<ListIcon/>}
                dense={dense}
            >
                <MenuItemLink
                    to="/registration"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.registrationList.usersList`, {
                        smart_count: 2,
                    })}
                    leftIcon={<GroupIcon />}
                    dense={dense}
                />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('queues')}
                isOpen={state.queues}
                name="pos.menu.queues"
                icon={<WorkIcon/>}
                dense={dense}
            >         
                <MenuItemLink
                    to={"/cron-board/queues"}
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`resources.queues.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<WorkIcon />}
                    dense={dense}
                    target="_blank"
                />
            </SubMenu>

            <SubMenu
                handleToggle={() => handleToggle('references')}
                isOpen={state.references}
                name="pos.menu.references"
                icon={<StorefrontIcon/>}
                dense={dense}
            >         
                <MenuItemLink
                    to={"/referencesSold"}
                    state={{ _scrollToTop: true }}
                    primaryText={`resources.mostSold.name`}
                    leftIcon={<StorefrontIcon />}
                    dense={dense}
                />
                 <MenuItemLink
                    to={"/referencesBought"}
                    state={{ _scrollToTop: true }}
                    primaryText={`resources.mostBought.name`}
                    leftIcon={<AddShoppingCartIcon />}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default Menu;