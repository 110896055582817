import {AutocompleteInput, required} from 'react-admin';
import { Fragment, useEffect, useState } from "react";

const apiUrlSuperadmin = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || 'http://localhost:8740') + '/users/superadmin';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

export default () => {
    const retailers = [];
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        const url = `${apiUrlSuperadmin}/retailers-options`;

        (async () => {
            const response = await fetch(url, {
                credentials: "include",
              });
            const result = await response.json();

            for (let retailerIndex in result.data) {
                let retailer = result.data[retailerIndex];
                retailer = {
                    id: retailer.id,
                    name: retailer.name
                }
                retailers.push(retailer)
            }

            if (active) {
                setOptions([...retailers]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading])

    return (
        <div>
            <AutocompleteInput
                noOptionsText="Attendere..."
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                label="Retailer"
                validate={[required()]}
                fullWidth
                source="fdirect_retailer_uuid"
                choices={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Retailer"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loading ? <CircularProgress color="inherit" size={15} /> : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                    />
                )}
            />
        </div>
    );
}