import * as React from 'react';

const Logo = () => {
    return (
        <svg width="132px" height="35px" viewBox="0 0 132 35" version="1.1">
            <title>Group</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Fdirect(R)" transform="translate(-77.000000, -193.000000)">
                    <g id="Group" transform="translate(77.975100, 193.395500)">
                        <path d="M33.8516393,18.8602055 C32.7225309,17.1036159 30.2967075,15.5979393 27.7873789,15.5979393 C23.73082,15.5979393 21.3049966,18.9437107 21.3049966,23.4187892 C21.3049966,27.8938678 23.73082,31.1976878 27.7873789,31.1976878 C30.2967075,31.1976878 32.7225309,29.7759139 33.8516393,28.0193243 L33.8516393,18.8602055 Z M33.8516393,30.4867015 C32.3461614,32.5359566 29.8785854,33.9998807 27.03464,33.9998807 C21.76507,33.9998807 18.0429291,29.9850743 18.0429291,23.4187892 C18.0429291,16.9781594 21.7233174,12.7959452 27.03464,12.7959452 C29.753129,12.7959452 32.2207049,14.134214 33.8516393,16.3506782 L33.8516393,5.6025765 L36.9882502,5.6025765 L36.9882502,33.4980548 L33.8516393,33.4980548 L33.8516393,30.4867015 Z" id="Fill-1" fill="#253451"></path>
                        <path d="M43.2617903,33.4979752 L46.3984012,33.4979752 L46.3984012,13.2978904 L43.2617903,13.2978904 L43.2617903,33.4979752 Z M42.718013,7.7772086 C42.718013,6.60614885 43.6801111,5.6862009 44.8092195,5.6862009 C45.9800804,5.6862009 46.9421786,6.60614885 46.9421786,7.7772086 C46.9421786,8.94826835 45.9800804,9.9103665 44.8092195,9.9103665 C43.6801111,9.9103665 42.718013,8.94826835 42.718013,7.7772086 L42.718013,7.7772086 Z" id="Fill-3" fill="#253451"></path>
                        <path d="M52.6713846,13.2978109 L55.8079956,13.2978109 L55.8079956,16.5598784 C57.4391287,14.4269193 59.7812482,12.8794901 62.5414897,12.8794901 L62.5414897,16.099805 C62.1651202,16.016101 61.7887508,15.9743485 61.286726,15.9743485 C59.3629274,15.9743485 56.7283412,17.5637291 55.8079956,19.1948622 L55.8079956,33.4978957 L52.6713846,33.4978957 L52.6713846,13.2978109 Z" id="Fill-5" fill="#253451"></path>
                        <path d="M68.6051933,22.1223128 L81.8628224,22.1223128 C81.8210698,19.1111583 79.8137661,15.3890174 75.2130322,15.3890174 C70.9055603,15.3890174 68.7726012,19.0274544 68.6051933,22.1223128 M75.2549836,12.7957861 C81.3611953,12.7957861 84.9578796,17.5637291 84.9578796,23.6277906 L84.9578796,24.4226797 L68.6051933,24.4226797 C68.8563051,28.2283257 71.5328427,31.406888 75.882266,31.406888 C78.1826329,31.406888 80.5245536,30.4867412 82.1137354,28.8556081 L83.619412,30.9050621 C81.6121082,32.9125647 78.8936193,33.9999205 75.5896005,33.9999205 C69.6088452,33.9999205 65.3013733,29.6922498 65.3013733,23.3768776 C65.3013733,17.5219765 69.4833887,12.7957861 75.2549836,12.7957861" id="Fill-6" fill="#253451"></path>
                        <path d="M98.9260576,12.7958458 C102.606446,12.7958458 104.781158,14.3015224 106.328587,16.309025 L104.237579,18.2328237 C102.89931,16.392729 101.184473,15.5978399 99.0934655,15.5978399 C94.785596,15.5978399 92.1090584,18.9018587 92.1090584,23.3769373 C92.1090584,27.851817 94.785596,31.1975884 99.0934655,31.1975884 C101.184473,31.1975884 102.89931,30.3613444 104.237579,28.5630022 L106.328587,30.4868009 C104.781158,32.4943035 102.606446,33.9999801 98.9260576,33.9999801 C92.9035498,33.9999801 88.8469909,29.3994451 88.8469909,23.3769373 C88.8469909,17.3963809 92.9035498,12.7958458 98.9260576,12.7958458" id="Fill-7" fill="#253451"></path>
                        <path d="M111.347224,29.273909 L111.347224,16.0582314 L108.001453,16.0582314 L108.001453,13.297791 L111.347224,13.297791 L111.347224,7.77710919 L114.483835,7.77710919 L114.483835,13.297791 L118.582544,13.297791 L118.582544,16.0582314 L114.483835,16.0582314 L114.483835,28.6046752 C114.483835,30.1103519 115.153069,31.1977077 116.53309,31.1977077 C117.411484,31.1977077 118.247927,30.8213382 118.666049,30.3612649 L119.586196,32.7033844 C118.791506,33.4563221 117.662198,33.9999006 115.822303,33.9999006 C112.852702,33.9999006 111.347224,32.2850635 111.347224,29.273909" id="Fill-8" fill="#253451"></path>
                        <path d="M12.3271237,0 C8.19262664,0 5.10969753,2.44331974 4.47724573,6.59769893 L10.9140979,6.59769893 C11.3688031,5.47415757 12.3138026,4.89320059 13.5504735,4.89320059 L19.1379594,4.89320059 L19.1379594,0 L12.3271237,0 Z" id="Fill-9" fill="#253451"></path>
                        <polygon id="Fill-10" fill="#253451" points="4.35139161 34 10.6144736 34 10.6144736 22.964601 4.35139161 22.964601"></polygon>
                        <path d="M126.191748,11.2227885 L126.586608,11.2227885 C127.047676,11.2227885 127.420665,11.0690993 127.420665,10.6961098 C127.420665,10.3664635 127.179097,10.1471631 126.652816,10.1471631 C126.433317,10.1471631 126.279628,10.1692323 126.191748,10.1915004 L126.191748,11.2227885 Z M126.169878,13.2632955 L125.335821,13.2632955 L125.335821,9.6646229 C125.66507,9.59901174 126.125938,9.5546745 126.718427,9.5546745 C127.398994,9.5546745 127.705775,9.6646229 127.969214,9.81831206 C128.166843,9.97200123 128.320532,10.2573104 128.320532,10.6082306 C128.320532,11.0032893 128.013154,11.3106677 127.574354,11.4422876 L127.574354,11.4858296 C127.925275,11.6172507 128.122903,11.8810871 128.232653,12.3634285 C128.342403,12.9123752 128.408014,13.1316755 128.495893,13.2632955 L127.596225,13.2632955 C127.486475,13.1316755 127.420665,12.802228 127.310916,12.3856965 C127.245304,11.990439 127.025805,11.8152771 126.564738,11.8152771 L126.169878,11.8152771 L126.169878,13.2632955 Z M123.953613,11.3764776 C123.953613,12.9781852 125.13859,14.2510416 126.762367,14.2510416 C128.342403,14.2510416 129.505112,12.9781852 129.505112,11.3979504 C129.505112,9.79624285 128.342403,8.50191366 126.740297,8.50191366 C125.13859,8.50191366 123.953613,9.79624285 123.953613,11.3764776 L123.953613,11.3764776 Z M130.427048,11.3764776 C130.427048,13.4169846 128.825142,15.0184933 126.740297,15.0184933 C124.677721,15.0184933 123.032074,13.4169846 123.032074,11.3764776 C123.032074,9.37951259 124.677721,7.7772086 126.740297,7.7772086 C128.825142,7.7772086 130.427048,9.37951259 130.427048,11.3764776 L130.427048,11.3764776 Z" id="Fill-11" fill="#253451"></path>
                        <path d="M14.9482099,12.1136886 L10.6144935,12.1136886 L10.6144935,8.31818254 C10.6144935,8.28656989 10.6170782,8.2573431 10.6176747,8.22592927 L4.35141149,8.22592927 L4.35141149,12.1138874 L0,12.1138874 L0,17.5452983 L4.35141149,17.5452983 L4.35141149,21.3362315 L10.6144935,21.3362315 L10.6144935,17.5452983 L14.9482099,17.5450995 L14.9482099,12.1136886 Z" id="Fill-12" fill="#109578"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Logo;