import { fetchUtils } from "react-admin";

const usersApi = (window.FTNET_SUPERADMIN_PROXY_BASE_URL || "http://localhost:8740") + "/users/superadmin";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json",
    });
  }
  options.credentials = "include";
  return fetchUtils.fetchJson(url, options);
};

export default {
  changePassword: (userId, password) => {
    const url = `${usersApi}/superadmin-users?id=${userId}`;

    return httpClient(url, { method: "PUT", body: JSON.stringify({ password }) });
  },
};
