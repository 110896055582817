import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  Pagination,
  SelectInput,
  useListContext,
} from "react-admin";
import React from "react";

const RecordPagination = (props) => <Pagination rowsPerPageOptions={[50, 100, 250, 500]} {...props} />;

const ReferenceFilters = (props) => (
  <Filter {...props}>
    <TextInput label="Retailer" source="retailerName" resettable alwaysOn />
    <TextInput label="Industria" source="companyName" resettable alwaysOn />
    <TextInput label="Brand" source="brand" resettable alwaysOn />
    <SelectInput
      label="Filtra per data"
      source="date"
      resettable
      alwaysOn
      choices={[
        { id: "30", name: "Ultimi 30 giorni" },
        { id: "90", name: "Ultimi 3 mesi" },
        { id: "180", name: "Ultimi 6 mesi" },
        { id: "365", name: "Ultimo anno" },
      ]}
    />
  </Filter>
);

const ReferenceList = () => {
  return (
    <List
      title="Referenze piu' vendute"
      pagination={<RecordPagination />}
      perPage="50"
      sort={{ field: "date", order: "DESC" }}
      filters={<ReferenceFilters />}
      filterDefaultValues={{ date: "30" }}
      resource="referencesSold"
    >
      <Datagrid bulkActionButtons={false}>
        <DateField locales="it-IT" sortBy="date" label="Data" source="date" />
        <TextField label="Codice referenza" source="referenceCode" />
        <TextField label="Brand" source="brand" />
        <TextField label="Nome referenza" source="referenceName" />
        <TextField label="Venduti" source="soldQuantity" />
        <TextField label="Categoria" source="category" />
        <TextField label="Degrassi" source="degrassi" />
        <TextField label="ID Retailer" source="retailerID" />
        <TextField label="Nome retailer" source="retailerName" />
        <TextField label="ID Industria" source="companyID" />
        <TextField label="Nome Industria" source="companyName" />
        <TextField label="Prezzo vendita" source="sellPrice" />
        <TextField label="Anno" source="anno" />
        <TextField label="Mese" source="mese" />
        <TextField label="Giorno" source="giorno" />
        <TextField label="Settimana" source="settimana" />
      </Datagrid>
    </List>
  );
};

const resource = {
  list: ReferenceList,
};

export default resource;
