import {useRecordContext} from "react-admin";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "@mui/material";

const FeesEditButton = ({resource}) => {
    const record = useRecordContext();
    const navigate = useNavigate();

    const handleEditClick = () => {
        navigate(`/${resource}/${record.id}`);
    };

    return <Button variant="contained" onClick={handleEditClick}>COMMISSIONI</Button>;
};

export default FeesEditButton;