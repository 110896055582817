
import React from "react";
import { useMutation } from 'react-query';
import { Button } from "@mui/material";
import loginAsProvider from "../provider/data/loginAs";

const LoginAsButton = ({userId, userStatus}) => {
    const { mutate, isLoading } = useMutation(
        ['loginAsUser', userId],
        () => loginAsProvider.loginAs(userId)
    );
    if(parseInt(userStatus) === 10){
        return <Button variant="contained" onClick={() => mutate()} disabled={isLoading}>Loggati</Button>;
    }else{
        return <Button variant="contained" disabled>Loggati</Button>;
    }
  };

  export default LoginAsButton;